import {useRecoilState} from "recoil";
import {AlertMessage} from "../interfaces";
import {alertsState} from "../store";
import {useCallback} from "react";

export const useAlerts = () => {
    const [alerts, setAlerts] = useRecoilState<AlertMessage[]>(alertsState);

    const addAlert = useCallback((alert: AlertMessage) => {
        setAlerts((prevAlerts) => prevAlerts.some(prevAlert => prevAlert.id === alert.id) ? [...prevAlerts] : [...prevAlerts, alert])
    }, [setAlerts])

    const addError = useCallback((error: any) => {
        addAlert({message: error?.error || error.message || 'An error occurred', variant: 'danger', id: error.id ? error.id : crypto.randomUUID()});
    }, [addAlert])

    const removeAlert = useCallback((id: string) => {
        setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
    }, [setAlerts])

    return {alerts, addAlert, addError, removeAlert};
}