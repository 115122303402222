import React, {useEffect, useState} from "react"
import {useParams} from "react-router";
import {getProject, updateProject} from "../../api/projects";
import {useAlerts} from "../../hooks/useAlerts";
import {Project} from "../../interfaces";
import {Full75CenteredContainer} from "../../components/containers/Full75CenteredContainer";
import {Title} from "../../components/Title";
import {Section} from "../../components/Section";
import {ProjectTaskList} from "./ProjectTaskList";
import {Col, Row} from "react-bootstrap";
import {formatDate, formatDateObj} from "../../utils/DateUtils";
import DatePicker from "react-datepicker";
import {ProjectStatusBadge} from "./ProjectStatus";

export const ProjectView = () => {

    const {addError} = useAlerts();
    const params = useParams()
    const [project, setProject] = useState<Project | null>()
    const [startDate, setStartDate] = useState<Date | null>(null)

    useEffect(() => {
        if (params.uuid) {
            getProject(params.uuid)
                .then(data => setProject(data.project))
                .catch(addError)
        }
    }, [addError, params.uuid]);

    useEffect(() => {
        if (project) {
            setStartDate(new Date(project.startDate))
        }
    }, [project]);

    if (!project) {
        return <div>Loading...</div>;
    }

    const handleStartDateChange = (startDate: Date) => {
        updateProject({startDate: startDate?.toISOString(), projectUuid: project.uuid})
            .then(_ => getProject(project.uuid))
            .then(data => setProject(data.project))
            .catch(addError)
    };

    const reloadState = () => {
        if (params.uuid) {
            getProject(params.uuid)
                .then(data => setProject(data.project))
                .catch(addError)
        }
    };

    return <Full75CenteredContainer>
        <Title className="mb-5" title={
            <div className="d-flex flex-row flex-wrap align-items-center">
                <span className="me-3 text-truncate">{project.name}</span>
                <div className="align-content-center" style={{fontSize: '0.6em'}}><ProjectStatusBadge status={project.status}/></div>
            </div>
        }
               subtitle={project?.code}>
            <Row>
                <Col>
                    <Row className="mt-2">
                        <Col className="text-strong">
                            Start date:
                        </Col>
                        <Col>
                            <DatePicker
                                className="form-control plain-input pointer"
                                selected={startDate}
                                dateFormat={'dd/MM/yyyy'}
                                onChange={date => handleStartDateChange(date!)}
                                value={startDate ? formatDateObj(startDate) : ""}/>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col className="text-strong">
                            Estimated end date:
                        </Col>
                        <Col>
                            {formatDate(project.estimatedEndDate)}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col className="text-strong">
                            Projected end date:
                        </Col>
                        <Col>
                            {formatDate(project.projectedEndDate)}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Title>
        <Section className="mb-5" title="Tasks">
            {project && <ProjectTaskList project={project} reloadState={reloadState}/>}
        </Section>
    </Full75CenteredContainer>
}