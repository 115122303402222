import React, {useEffect} from 'react';
import {Outlet} from "react-router";
import {Sidebar} from './components/sidebar/Sidebar';
import {TopNavBar} from "./components/TopNavBar";
import {AvoidRouter} from "./routes/process-view/graph/LibAvoidRouter";
import {DismissibleAlertList} from "./components/alerts/AlertsList";

function App() {

    useEffect(() => {
        AvoidRouter.load().then(_ => console.log("avoidlib-js loaded"))
    }, []);

    return (<>
            <TopNavBar/>
            <div className="app-container" style={{display: "flex", height: "100%"}}>
                <Sidebar/>
                <div className="content" style={{width: "100%"}}>
                    <DismissibleAlertList/>
                    <Outlet/>
                </div>
            </div>
        </>
    )
}

export default App;
