import React from "react";
import {Title} from "../../components/Title";
import {useRecoilValue} from "recoil";
import {organizationState} from "../../store";
import {OrganizationTeams} from "./OrganizationTeams";
import {Full75CenteredContainer} from "../../components/containers/Full75CenteredContainer";

export function OrganizationSettingsView() {
    const organization = useRecoilValue(organizationState)

    return (<Full75CenteredContainer>
        <Title title={"Organization settings"} subtitle={organization?.name} className="mb-5"/>
        <OrganizationTeams/>
    </Full75CenteredContainer>)
}