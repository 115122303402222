import {OrganizationMember} from "../../interfaces";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import React from "react";
import {ROLES} from "../../utils/Constants";

interface MemberRowProps {
    member: OrganizationMember,
    deleteAllowed: boolean,
    handleDelete: (member: OrganizationMember) => void,
    isCurrentUser: boolean
}

export const MemberRow = (props: MemberRowProps) => {
    return (
        <tr>
            <td className="align-middle">
                {props.member?.name} {props.isCurrentUser ? (<text className="text-muted">(you)</text>) : ''}
            </td>
            <td className="align-middle">
                {props.member?.email}
            </td>
            <td className="align-middle">
                {props.member.teams.map(team => <span key={team.name}>{team.name}</span>)}
            </td>
            <td className="align-middle">
                {ROLES[props.member.role]}
            </td>
            <td className="align-middle">
                {props.deleteAllowed && props.member.role !== 'OWNER' &&
                    (<XMarkIcon onClick={() => props.handleDelete(props.member)}
                                className="link-danger icon-style pointer"/>)}
            </td>
        </tr>
    )
}