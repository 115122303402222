import {Col, OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";

interface HoverTooltipProps {
    hidden?: boolean;
    children: React.ReactNode;
    text: string;
    delay?: number;
}

export const HoverTooltip = ({hidden, children, text, delay}: HoverTooltipProps) => {

    return (
        <Col className="d-flex align-items-center">
            <OverlayTrigger
                delay={{show: delay || 150, hide: 200}}
                overlay={(props: any) => {
                    return !hidden ? <Tooltip id="button-tooltip" {...props}>
                        {text}
                    </Tooltip> : <></>
                }}
                placement="right">
                <div>
                    {children}
                </div>
            </OverlayTrigger>
        </Col>
    );
}