import {MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import {dia} from "@joint/core";
import Paper = dia.Paper;
import svgPanZoom from "svg-pan-zoom";

type UseSvgPanZoomReturn = {
    initialized: boolean;
    fitOnScreen: () => void;
};

export const useSvgPanZoom = (paperRef: MutableRefObject<Paper | null>, canvasRef: MutableRefObject<HTMLDivElement | null>): UseSvgPanZoomReturn => {
    const panZoomRef = useRef<SvgPanZoom.Instance | null>(null);
    const [initialized, setInitialized] = useState(false);

    const fitOnScreen = useCallback((): void => {
        panZoomRef.current?.resetZoom().updateBBox().resize().center().fit().zoomOut();
    }, [])

    const enablePan = useCallback(() => {
        panZoomRef.current?.enablePan();
    }, []);

    const disablePan = useCallback(() => {
        panZoomRef.current?.disablePan();
    }, []);

    const initPanZoom = useCallback((paper: Paper): SvgPanZoom.Instance => {
        return svgPanZoom(paper!.svg, {
            viewportSelector: paperRef.current?.layers!,
            zoomEnabled: true,
            panEnabled: true,
            fit: false,
            center: false,
            dblClickZoomEnabled: true,
            maxZoom: 2,
            minZoom: 0.1,
        });
    }, [paperRef])

    useEffect(() => {
        let resizeObserver: ResizeObserver;
        paperRef.current?.on('render:done', function () {
            if (!panZoomRef.current) {
                panZoomRef.current = initPanZoom(paperRef.current!);
                fitOnScreen()
                setInitialized(true);
            }
        });

        resizeObserver = new ResizeObserver(() => {
            fitOnScreen()
        });
        resizeObserver.observe(canvasRef.current!);

        paperRef.current?.on('cell:pointerdown', function (cellView, evt) {
            evt.stopPropagation();
            disablePan();
        });

        paperRef.current?.on('cell:pointerup', function (cellView, evt) {
            enablePan();
        });

        return () => {
            resizeObserver?.disconnect()
        }
    }, [canvasRef, disablePan, enablePan, fitOnScreen, initPanZoom, paperRef]);

    return {
        initialized,
        fitOnScreen
    }
}