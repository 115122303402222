import React, {useEffect, useMemo, useState} from "react"
import {ProjectSubtask} from "../../interfaces";
import {Col, Form, Row} from "react-bootstrap";
import {formatDate, formatDateObj} from "../../utils/DateUtils";
import {updateProjectSubtask} from "../../api/projects";
import {useAlerts} from "../../hooks/useAlerts";
import DatePicker from "react-datepicker";

interface ProjectSubtaskRowProps {
    subtask: ProjectSubtask,
    reloadState: () => void,
}

export const ProjectSubtaskRow = ({subtask, reloadState}: ProjectSubtaskRowProps) => {

    const {addError} = useAlerts()
    const [finishedDate, setFinishedDate] = useState<Date | null>(null)

    useEffect(() => {
        setFinishedDate(subtask.finishedAt ? new Date(subtask.finishedAt) : null)
    }, [subtask.finishedAt]);

    const handleFinishedDateChange = (date: Date | null) => {
        updateProjectSubtask({
            subtaskUuid: subtask.uuid,
            finishedDate: date ? date.toISOString() : null,
        }).then(() => reloadState())
            .catch(addError)
    }

    return <Row className="mt-2">
        <Col md={2}>
            <Form.Check
                isValid
                type="checkbox"
                checked={!!subtask.finishedAt}
                className="project-checkbox"
                id={`checked-${subtask.uuid}`}
                onClick={e => e.stopPropagation()}
                onChange={e => {
                    e.stopPropagation()
                    handleFinishedDateChange(e.currentTarget.checked ? new Date() : null)
                }}
            />
        </Col>
        <Col md={3}>
            {subtask.name}
        </Col>
        <Col>
            <DatePicker
                className="form-control plain-input-sm pointer"
                selected={finishedDate}
                dateFormat={'dd/MM/yyyy'}
                onChange={date => handleFinishedDateChange(date!)}
                value={subtask.finishedAt ? formatDate(subtask.finishedAt) : "Not completed"}/>
        </Col>
    </Row>
}