import React, {useEffect, useMemo, useState} from "react";
import {ProjectLink, ProjectTask} from "../../interfaces";
import {Accordion, Button, Col, FormControl, Row} from "react-bootstrap";
import {addWeekdays, formatDate, formatDateObj} from "../../utils/DateUtils";
import {ProjectSubtaskList} from "./ProjectSubtaskList";
import TextareaAutosize from "react-textarea-autosize";
import {updateProjectTask} from "../../api/projects";
import {useAlerts} from "../../hooks/useAlerts";
import {ProjectTaskStatusBadge} from "./ProjectTaskStatus";
import {Section} from "../../components/Section";
import {HoverTooltip} from "../../components/HoverTooltip";

interface ProjectTaskRowParams {
    task: ProjectTask,
    reloadState: () => void;
}

export const ProjectTaskRow = ({task, reloadState}: ProjectTaskRowParams) => {

    const finishedAt = useMemo(() => task.finishedAt ? formatDate(task.finishedAt) : null, [task.finishedAt]);
    const estimated = useMemo(() => task.startedAt ? addWeekdays(task.startedAt, task.duration || 0) : null, [task.duration, task.startedAt]);
    const [description, setDescription] = useState<string>()
    const {addError} = useAlerts()

    useEffect(() => {
        setDescription(task.description);
    }, [task.description]);

    const handleTaskInProgress = () => {
        updateProjectTask({
            skip: false,
            taskUuid: task.uuid,
            finishedDate: null,
        }).then(() => reloadState())
            .catch(addError)
    }

    const handleTaskCompleted = () => {
        updateProjectTask({
            skip: false,
            taskUuid: task.uuid,
            finishedDate: new Date().toISOString()
        }).then(() => reloadState())
            .catch(addError)
    }

    const handleTaskSkipped = () => {
        updateProjectTask({
            taskUuid: task.uuid,
            finishedDate: new Date().toISOString(),
            skip: true,
        }).then(() => reloadState())
            .catch(addError)
    }

    const handleDescriptionChanged = () => {
        updateProjectTask({
            taskUuid: task.uuid,
            finishedDate: task.finishedAt,
            description: description
        }).catch(addError)
    }

    return (
        <Accordion.Item eventKey={task.uuid} className="border-0">
            <Accordion.Header className="narrow-header">
                <Row className="w-100 ms-1">
                    <Col md={4}>
                        {task.name}
                    </Col>
                    <Col md={3}>
                        <ProjectTaskStatusBadge status={task.status}></ProjectTaskStatusBadge>
                    </Col>
                    <Col md={2}>
                        <span>{estimated && task.status !== 'SKIPPED' ? formatDateObj(estimated) : '-'}</span>
                    </Col>
                    <Col md={2}>
                        <span>{finishedAt && task.status !== 'SKIPPED' ? finishedAt : '-'}</span>
                    </Col>
                </Row>
            </Accordion.Header>
            <Accordion.Body className="small validated">
                <Row className="rounded-border-gray p-3 pt-4">
                    <Section title="Details">
                        <Row>
                            <Col md={4}>
                                <Row>
                                    <Col md="4" className="text-strong">
                                        Team:
                                    </Col>
                                    <Col>
                                        {task.team.name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4" className="text-strong">
                                        Tool:
                                    </Col>
                                    <Col>
                                        {task.tool.name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4" className="text-strong">
                                        Started:
                                    </Col>
                                    <Col>
                                        {task.startedAt ? formatDate(task.startedAt) : '-'}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4" className="text-strong">
                                        SLA:
                                    </Col>
                                    <Col>
                                        {task.duration} days
                                    </Col>
                                </Row>
                                {task.dependsOn.length > 0 && <Row>
                                    <Col md="4" className="text-strong">
                                        Previous:
                                    </Col>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            {task.dependsOn.map(link => (
                                                <span key={`link_${link.uuid}_to_${task.uuid}`}>{link.name}</span>))}
                                        </div>
                                    </Col>
                                </Row>}
                                {task.dependents.length > 0 && <Row>
                                    <Col md="4" className="text-strong">
                                        Next:
                                    </Col>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            {task.dependents.map(link => (
                                                <span key={`link_${task.uuid}_to_${link.uuid}`}>{link.name}</span>))}
                                        </div>
                                    </Col>
                                </Row>}
                            </Col>
                            <Col md={8}>
                                <Row className="h-100">
                                    <Col className="text-strong" md={3}>
                                        Description
                                    </Col>
                                    <Col>
                                        <FormControl
                                            as={TextareaAutosize}
                                            minRows={5}
                                            className="w-100 plain-input-sm bg-white"
                                            value={description}
                                            onChange={e => setDescription(e.currentTarget.value)}
                                            onBlur={handleDescriptionChanged}
                                        >
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Section>

                    {task.subtasks.length > 0 &&
                        <Row>
                            <Section title="Subtasks" className="rounded-border-gray">
                                <Col md={12}>
                                    <ProjectSubtaskList reloadState={reloadState} task={task}/>
                                </Col>
                            </Section>
                        </Row>}
                    <Row>
                        <Section title="Actions">
                            <div className="d-flex flex-row">
                                {['ON_TRACK', 'DELAYED'].includes(task.status) &&
                                    <Button className="me-2" size="sm" variant="success"
                                            onClick={() => handleTaskCompleted()}>Complete task</Button>}
                                {['ON_TRACK', 'NOT_STARTED', 'DELAYED'].includes(task.status) &&
                                    <HoverTooltip text="Skip the task in this project. The task will not be included in project total duration." delay={700}>
                                        <Button className="me-2" size="sm" variant="secondary"
                                                onClick={handleTaskSkipped}>Skip task</Button>
                                    </HoverTooltip>}
                                {['SKIPPED', 'COMPLETED'].includes(task.status) &&
                                    <HoverTooltip text="Clear finished at date." delay={700}>
                                        <Button className="me-2" size="sm" variant="outline-danger"
                                                onClick={() => handleTaskInProgress()}>Reset task</Button>
                                    </HoverTooltip>
                                }

                            </div>
                        </Section>
                    </Row>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    );
}
