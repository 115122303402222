import {useNavigate} from "react-router";
import {Nav} from "react-bootstrap";
import React from "react";

interface SidebarLinkProps {
    className?: string
    iconComponent: React.ComponentType<{ style?: React.CSSProperties }>,
    label: string;
    onClick?: () => void;
    to?: string;
    collapsed?: boolean
}

export const SidebarLink = ({collapsed, onClick, to, className, iconComponent, label}: SidebarLinkProps) => {
    const navigate = useNavigate();
    const IconComponent = iconComponent;

    const doAction = () => {
        if (onClick) {
            onClick();
        } else if (to) {
            navigate(to);
        }
    }

    return (
        <Nav.Link onClick={doAction} className={`pointer link-secondary d-flex align-items-center ${className}`}>
            <IconComponent style={{width: '24px', marginRight: collapsed ? 0 : '10px'}}/>
            {!collapsed && <span>{label}</span>}
        </Nav.Link>
    )

}