import React, {useEffect} from "react";
import {useRecoilState} from "recoil";
import {wizardProcessState} from "../../../store";
import WizardItemSelect from "../WizardItemSelect";
import WizardFrame from "../WizardFrame";
import {useNavigate, useParams} from "react-router";
import {useRoles} from "../../../hooks/UsePermissions";
import {Section} from "../../../components/Section";
import {Title} from "../../../components/Title";
import WizardProgress from "../WizardProgress";
import {useAlerts} from "../../../hooks/useAlerts";
import {getWizardProcess, updateWizardProcess } from "../../../api/wizard";

export default function WizardTeamsAndTools() {
    const [wizardProcess, setWizardProcess] = useRecoilState(wizardProcessState)
    const navigate = useNavigate()
    const [isOwner] = useRoles()
    const params = useParams();
    const {addError} = useAlerts();

    useEffect(() => {
        if (params.uuid) {
            getWizardProcess(params.uuid)
                .then(data => setWizardProcess(data))
                .catch(error => addError(error))
        }
    }, [addError, setWizardProcess, params.uuid]);

    const handleNext = () => {
            updateWizardProcess(wizardProcess)
                .then(_ => navigate(`/wizard/arrange/${wizardProcess.uuid}`))
                .catch(error => addError(error))
    }

    const handlePrev = () => {
        navigate(`/wizard/tasks/${wizardProcess.uuid}`)
    }

    const nextEnabled = wizardProcess.teams.some(team => team.selected) && wizardProcess.tools.some(tool => tool.selected)

    return (
        <>
            <WizardFrame next={handleNext} prev={handlePrev} nextDisabled={!nextEnabled}>
                <div className="d-flex flex-row justify-content-between">
                    <Title title="Let's learn more about your process" className="mb-4" subtitle={wizardProcess.name}/>
                    <div className="mt-2">
                        <WizardProgress/>
                    </div>
                </div>

                <Section title="Which teams are involved in the process?">
                    <WizardItemSelect itemType="teams" hideAdd={!isOwner}></WizardItemSelect>
                </Section>

                <Section title="Which systems are used to manage the process?">
                    <WizardItemSelect itemType="tools"></WizardItemSelect>
                </Section>
            </WizardFrame>
        </>)
}