import {Button, Form, FormControl, FormText, Modal, Row} from "react-bootstrap";
import React, {useRef, useState} from "react";
import PlusIcon from '@heroicons/react/24/solid/PlusIcon'

interface ProcessItemAddProps {
    itemType: string
    size?: string;
    handleNewItem: (item: string) => void
}

export default function ItemAddModal(props: ProcessItemAddProps) {
    const [showModal, setShowModal] = useState(false)
    const [newItem, setNewItem] = useState<string | null>(null)
    const inputRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (newItem) {
            props.handleNewItem(newItem);
        }
        setShowModal(false);
    }

    const style = {
        borderRadius: '50%',
        width: props.size ? props.size : '28px',
        height: props.size ? props.size : '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0',
    }

    const getMaxLength = (): number => {
        if (props.itemType === 'tasks') {
            return 40
        } else if (props.itemType === 'tools') {
            return 15
        } else if (props.itemType === 'teams') {
            return 15
        }
        return 100;
    }

    return (<>
            <Modal className="h-75 modal-sm" centered
                   show={showModal}
                   onShow={() => inputRef?.current?.focus()}
                   onHide={() => setShowModal(false)}>
                <Modal.Body className="m-3">
                    <Row className="m-2 justify-content-center">
                        <Form onSubmit={handleSubmit}>
                            <FormControl
                                ref={inputRef}
                                value={newItem || ''}
                                type="text"
                                required={true}
                                minLength={2}
                                maxLength={getMaxLength()}
                                onChange={(e) => setNewItem(e.currentTarget.value)}
                            />
                            <div>
                                <FormText muted className="ms-auto">{`${newItem?.length || 0}/${getMaxLength()}`}</FormText>
                            </div>
                        </Form>
                    </Row>
                </Modal.Body>
            </Modal>
            <div key={`${props.itemType}_add_container`}
                 className="m-2 d-flex align-items-center justify-content-center">
                <Button
                    key={`${props.itemType}_add`}
                    id={`${props.itemType}_add`}
                    type="button"
                    ref={buttonRef}
                    variant="outline-primary"
                    style={style}
                    onClick={() => {
                        buttonRef?.current?.blur()
                        setNewItem(null)
                        setShowModal(true)
                    }}
                > <PlusIcon className="icon-style"/></Button>
            </div>
        </>
    );
}