/**
 * Formats date string to human friendly date, with dates falling to today and yesterday replaced by text
 */
const formatDate = (dateStr: string) => {
    const dateToCheck = new Date(dateStr);
    return formatDateObj(dateToCheck)
}

const formatDateObj = (dateToCheck: Date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    today.setHours(0, 0, 0, 0);
    yesterday.setHours(0, 0, 0, 0);
    const localDateToCheck = new Date(dateToCheck.getTime() - (dateToCheck.getTimezoneOffset() * 60000));
    localDateToCheck.setHours(0, 0, 0, 0);
    if (localDateToCheck.getTime() === today.getTime()) {
        return 'Today';
    } else if (localDateToCheck.getTime() === yesterday.getTime()) {
        return 'Yesterday';
    } else {
        return localDateToCheck.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }
}

const addWeekdays = (dateStr: string, daysToAdd: number) => {
    const date = new Date(dateStr);
    let daysAdded = 0;

    while (daysAdded < daysToAdd) {
        date.setDate(date.getDate() + 1);

        if (date.getDay() !== 0 && date.getDay() !== 6) {
            daysAdded++;
        }
    }

    return date;
}


export {formatDate, addWeekdays, formatDateObj}
