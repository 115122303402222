import React, {useEffect, useState} from "react"
import {Section} from "../../components/Section";
import {useRecoilValue} from "recoil";
import {organizationUuidState} from "../../store";
import {NullableOption, Team} from "../../interfaces";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import {TEAM_OPTIONS} from "../../utils/Constants";
import {SaveButton} from "../../components/SaveButton";
import {useRoles} from "../../hooks/UsePermissions";
import {useAlerts} from "../../hooks/useAlerts";
import {deleteTeams, getTeams, saveTeams} from "../../api/teams";

const animatedComponents = makeAnimated();
const customComponents = {
    ...animatedComponents,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
};

export const OrganizationTeams = () => {

    const organizationUuid = useRecoilValue(organizationUuidState);
    const [teams, setTeams] = useState<Team[]>([])
    const [isOwner] = useRoles()
    const [selectedTeams, setSelectedTeams] = useState<NullableOption[]>([])
    const [teamOptions, setTeamOptions] = useState<NullableOption[]>([])
    const {addError} = useAlerts()

    useEffect(() => {
        getTeams()
            .then(data => setTeams(data))
            .catch(error => addError(error))
    }, [organizationUuid]);

    useEffect(() => {
        setSelectedTeams([...teams.map(team => ({value: team.name, label: team.name, uuid: team.uuid}))])
        const teamOptions = teams.map(team => ({value: team.name, label: team.name, uuid: team.uuid}))
        const additionalOptions = TEAM_OPTIONS.filter(team => !teamOptions.some(option => option.label === team.label))
        setTeamOptions([...teamOptions, ...additionalOptions])
    }, [teams]);


    const handleSaveTeams = (done: () => void) => {
        const teamUuidsToBeDeleted = teams.filter(team => !selectedTeams.some(option => option?.label === team.name)).map(team => team.uuid)
        const teamNamesToBeAdded = selectedTeams.filter((option: any) => !option.uuid).map(option => option!.label)
        saveTeams(teamNamesToBeAdded)
            .then(_ => deleteTeams(teamUuidsToBeDeleted))
            .then(_ => getTeams())
            .then(data => setTeams(data))
            .catch(error => addError(error))
        done()
    };

    const handleTeamsChange = (newOptions: NullableOption[]) => {
        setSelectedTeams(newOptions)
    };

    return (
        <Section title="Teams">
            <CreatableSelect
                onKeyDown={e => e.key === "Enter" && e.stopPropagation()}
                className="w-100"
                noOptionsMessage={() => "Type to add more"}
                options={teamOptions}
                isDisabled={!isOwner}
                components={customComponents}
                closeMenuOnSelect={false}
                isMulti={true}
                onChange={handleTeamsChange}
                value={selectedTeams}
            />
            {isOwner && <div className="d-flex flex-row mt-3">
                <div className="ms-auto me-auto">
                    <SaveButton variant="secondary" handleSave={handleSaveTeams}/>
                </div>
            </div>}
        </Section>
    )
}