import {getRecoil} from "recoil-nexus";
import {organizationUuidState} from "../store";
import fetcher from "./fetcher";
import {ProcessTemplate} from "../interfaces";

export const saveTemplate = async (name: string) => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('POST', `/template`, organizationUuid, {name});
    } catch (e) {
        return Promise.reject(e);
    }
}

export const saveTemplateTask = async ({templateUuid, name}: { templateUuid: string, name: string }) => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('POST', `/template/${templateUuid}/task`, organizationUuid, name);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getTemplates = async (): Promise<ProcessTemplate[]> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('GET', '/templates', organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getTemplate = async (templateUuid: string): Promise<ProcessTemplate> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('GET', `/template/${templateUuid}`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}