import React from "react";
import {Button, Container, Navbar} from "react-bootstrap";
import {Full75CenteredContainer} from "../../components/containers/Full75CenteredContainer";

export interface WizardFrameProps {
    children: any,
    next?: () => void,
    prev?: () => void,
    nextDisabled?: boolean,
    nextLabel?: string,
    prevLabel?: string
}

export default function WizardFrame(props: WizardFrameProps) {

    return (<>
        <Full75CenteredContainer>
            {props.children}
        </Full75CenteredContainer>
        <Navbar fixed="bottom">
            <Container className="d-flex justify-content-end mb-2 mt-auto">
                {props.prev && (<Button className="ms-auto ps-5 pe-5 me-3" variant="outline-secondary"
                                        onClick={props?.prev}>{props.prevLabel || 'Back'}</Button>)}
                {props.next && (<Button className="ps-5 pe-5" variant="secondary"
                                        disabled={props.nextDisabled}
                                        onClick={props?.next}>{props.nextLabel || 'Next'}</Button>)}
            </Container>
        </Navbar>
    </>)
}