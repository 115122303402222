import React, {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {organizationState} from "../../store";
import {ActiveUsersList} from "./ActiveUsersList";
import {InvitedUsersList} from "./InvitedUsersList";
import {OrganizationMember} from "../../interfaces";
import {Title} from "../../components/Title";
import {useRoles} from "../../hooks/UsePermissions";
import {Full75CenteredContainer} from "../../components/containers/Full75CenteredContainer";
import {useAlerts} from "../../hooks/useAlerts";
import {getOrganizationMembers} from "../../api/organizations";


export function ManageUsersView() {
    const organization = useRecoilValue(organizationState);
    const [isOwner] = useRoles()
    const [members, setMembers] = useState<OrganizationMember[]>([])
    const {addError} = useAlerts();

    useEffect(() => {
        getOrganizationMembers()
            .then(data => setMembers(data))
            .catch(error => addError(error))
    }, [addError, organization?.uuid]);

    return <Full75CenteredContainer>
        <Title title="User management" subtitle={organization?.name} className="mb-5"/>
        <ActiveUsersList organizationUuid={organization?.uuid} members={members} setMembers={setMembers} isOwner={isOwner}/>
        <InvitedUsersList organizationUuid={organization?.uuid} members={members} isOwner={isOwner}/>
    </Full75CenteredContainer>
}