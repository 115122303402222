import {Button, ButtonGroup, Form, Row} from "react-bootstrap";
import {SaveButton} from "../../components/SaveButton";
import {DocumentIcon, ShareIcon, TableCellsIcon, ViewColumnsIcon} from "@heroicons/react/24/solid";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import React from "react";
import {getProcess, saveProcess} from "../../api/process";
import jsPDF from "jspdf";
import {findRelatedTasks} from "./graph/ProcessUtils";
import autoTable from "jspdf-autotable";
import {useRecoilState, useSetRecoilState} from "recoil";
import {processActiveTaskUuid, processState, processViewEditModeState, resettingGraphState} from "../../store";
import {Process} from "../../interfaces";
import {useAlerts} from "../../hooks/useAlerts";

interface ProcessViewMenuProps {
    processUuid: any,
    triggerPdfExport: (() => void) | null,
    setViewMode: (value: (((prevState: ("graph" | "table" | "both")) => ("graph" | "table" | "both")) | "graph" | "table" | "both")) => void,
    viewMode: "graph" | "table" | "both"
}

export const ProcessViewMenu = ({processUuid, triggerPdfExport, setViewMode, viewMode}: ProcessViewMenuProps) => {
    const [process, setProcess] = useRecoilState<Process>(processState)
    const setResettingGraph = useSetRecoilState(resettingGraphState);
    const [activeTaskUuid, setActiveTaskUuid] = useRecoilState(processActiveTaskUuid);
    const [editMode, setEditMode] = useRecoilState<boolean>(processViewEditModeState)
    const {addError} = useAlerts()
    
    const handleProcessNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        setProcess(prev => ({...prev, name: event.currentTarget.value!}))
    }

    const handleProcessSave = (done: () => void) => {
        saveProcess(process)
            .then(_ => setProcess(prev => ({...prev})))
            .then(_ => done())
            .catch(error => addError(error))
    }

    const handleExportTable = () => {
        const doc = new jsPDF();

        const columns = ['Nr', 'Task',  'Team', 'Tool'];
        const rows: any[] = [];

        process.process.tasks.forEach((task, index) => {
            rows.push([index + 1, task.name, task.team.name, task.tool.name])
            if (task.description) {
                rows.push([{
                    content: task.description,
                    colSpan: 5,
                    styles: {halign: 'left'}
                }, '', '', '', ''])
            }
            const {dependsOn, dependedBy} = findRelatedTasks(task.uuid, process.process.tasks, process.process.links)
            if (dependsOn.length > 0) rows.push([{
                content: "After: " + dependsOn.map(task => task.name).join(", "),
                colSpan: 5,
                styles: {halign: 'left'}
            }, '', '', '', ''])
            if (dependedBy.length > 0) rows.push([{
                content: "Before: " + dependedBy.map(task => task.name).join(", "),
                colSpan: 5,
                styles: {halign: 'left'}
            }, '', '', '', ''])
        })

        autoTable(doc, {
            head: [columns],
            body: rows,
            startY: 20,
            theme: "striped",
            styles: {fontSize: 10, cellPadding: 3},
            columnStyles: {2: {halign: "right"}},
        });

        doc.save("process.pdf");
    }

    const handleEditMode = (nextEditMode: boolean) => {
        if (!nextEditMode) {
            const currentActiveTaskUud = activeTaskUuid;
            setResettingGraph(true)
            getProcess(processUuid)
                .then(response => {
                    setProcess({...response});
                    setActiveTaskUuid(currentActiveTaskUud)
                })
                .catch(error => addError(error))
        }
        setEditMode(nextEditMode)
    };

    return (
        <Row className="mb-4">
            <div className="d-flex flex-row">
                <div className="w-100">
                    <h3 className="d-flex">
                        <Form.Control as="input"
                                      className="plain-input h3"
                                      disabled={!editMode}
                                      value={process?.name}
                                      plaintext
                                      style={{textDecoration: 'ThreeDHighlight'}}
                                      placeholder={`Set task name ...`}
                                      onChange={handleProcessNameChange}/>
                    </h3>
                </div>

                {editMode && <div className="ms-3">
                    <SaveButton handleSave={handleProcessSave}/>
                </div>}
                <div className="ms-3">
                    <Button size="sm" variant="secondary" style={{width: '60px'}}
                            onClick={() => handleEditMode(!editMode)}>{editMode ? 'View' : 'Edit'}</Button>
                </div>
                <div className="ms-3">
                    <Button size="sm"
                            style={{height: 30}}
                            className="d-flex align-items-center"
                            variant="secondary"
                            onClick={() => triggerPdfExport!()}><DocumentIcon
                        className={"icon-style"}/></Button>
                </div>
                <div className="ms-3">
                    <Button size="sm"
                            style={{height: 30}}
                            className="d-flex align-items-center"
                            variant="secondary"
                            onClick={() => handleExportTable()}><TableCellsIcon
                        className={"icon-style"}/></Button>
                </div>
                <div className="ms-3">
                    <ButtonGroup>
                        <Button
                            className="d-flex align-items-center" style={{height: 30}}
                            size="sm" onClick={() => setViewMode('graph')} active={viewMode === 'graph'}
                            variant="outline-secondary"><ShareIcon className="icon-style"/></Button>
                        <Button
                            className="d-flex align-items-center"
                            size="sm" onClick={() => setViewMode('table')} active={viewMode === 'table'}
                            variant="outline-secondary"><Bars3Icon className="icon-style"/></Button>
                        <Button
                            className="d-flex align-items-center"
                            size="sm" onClick={() => setViewMode('both')} active={viewMode === 'both'}
                            variant="outline-secondary"><ViewColumnsIcon className="icon-style"/></Button>
                    </ButtonGroup>
                </div>
            </div>
        </Row>
    )
}