import {Card} from "react-bootstrap";
import React from "react";
import DroppableElement from "./DroppableElement";
import SortableItem from "./SortableItem";
import TaskCard from "./TaskCard";

export default function DroppableCardContainer({id, items, activeId, overId}: {
    items: string[],
    activeId: string | null,
    overId: string | null,
    id: string
}) {
    const cardStyle = {
        width: '12rem',
        height: '12rem',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
    };
    const dropCardStyle = {
        ...cardStyle,
        transition: 'box-shadow 0.3s ease',
        ...(overId === 'empty_slot' && {boxShadow: '0 0 20px 5px rgba(255,158,123, 0.5)'})
    };
    return (
        <DroppableElement scroll={false} items={items} id={id}>
            <div className="d-flex flex-row flex-wrap">

                {items.map((item) => (
                    <SortableItem key={`card_${item}`} id={item}
                                  invisible={activeId === item}>
                        <TaskCard style={cardStyle} title={item}></TaskCard>
                    </SortableItem>
                ))}
                <SortableItem id={"empty_slot"} invisible={false} disabled={true}>
                    <Card className="me-3 card-slot" style={dropCardStyle}>
                        <div className="m-auto text-primary text-center">
                            <p style={{fontSize: '0.75rem'}}>Drag and drop next step</p>
                        </div>
                    </Card>
                </SortableItem>
            </div>
        </DroppableElement>
);
};