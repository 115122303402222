import React from "react";
import {UserOrganizationList} from "./UserOrganizationList";
import {EditUserProfile} from "./EditUserProfile";
import {Title} from "../../components/Title";
import {useRecoilValue} from "recoil";
import {userState} from "../../store";
import {UserInvitations} from "../../components/UserInvitations";
import {Full75CenteredContainer} from "../../components/containers/Full75CenteredContainer";


export function UserProfileView() {
    const user = useRecoilValue(userState);
    return <Full75CenteredContainer>
        <Title title="Profile" subtitle={user?.email} className="mb-5"/>
        <EditUserProfile></EditUserProfile>
        <UserOrganizationList></UserOrganizationList>
        <UserInvitations title="My invitations"></UserInvitations>
    </Full75CenteredContainer>
}

