import graphlib from 'graphlib';
import {dia} from "@joint/core";
import {Process, ProcessTask} from "../../../interfaces";

export const rankNodes = (graph: dia.Graph): string[] => {
    const g = new graphlib.Graph({ directed: true });

    // Add nodes with their durations
    graph.getElements().forEach((node) => {
        g.setNode(node.id as string, 0)
    });

    // Add edges
    graph.getLinks().forEach((link) => {
        const sourceId = link.get('source').id;
        const targetId = link.get('target').id;
        if (sourceId && targetId) {
            g.setEdge(sourceId, targetId);
        }
    });

    // Perform Topological Sort
    return graphlib.alg.topsort(g);
}

export const findLongestPath = (process: Process): { path: string[], maxDistance: number } => {
    const g = new graphlib.Graph({ directed: true });

    process.process.tasks.forEach((task: ProcessTask) => {
        g.setNode(task.uuid, task.duration || 0)
    });

    process.process.links.forEach((link) => {
        const sourceId = link.sourceTaskUuid;
        const targetId = link.targetTaskUuid;
        if (sourceId && targetId) {
            g.setEdge(sourceId, targetId);
        }
    });

    // Perform Topological Sort
    const sortedNodes = graphlib.alg.topsort(g);

    // Initialize distances and predecessors
    const distances: { [key: string]: number } = {};
    const predecessors: { [key: string]: string | null } = {};

    sortedNodes.forEach((nodeId) => {
        distances[nodeId] = -Infinity;
        predecessors[nodeId] = null;
    });

    g.nodes().filter((nodeId) => g.inEdges(nodeId)?.length === 0)
        .forEach(nodeId => distances[nodeId] = g.node(nodeId) || 0);

    // Relax edges
    sortedNodes.forEach((nodeId: string) => {
        g.outEdges(nodeId)?.forEach((edge) => {
            const targetId = edge.w;
            if (distances[targetId] < distances[nodeId] + g.node(targetId)) {
                distances[targetId] = distances[nodeId] + g.node(targetId);
                predecessors[targetId] = nodeId;
            }
        });
    });

    let maxNode: string | null = null;
    let maxDistance = -Infinity;

    sortedNodes.forEach((nodeId) => {
        const nodeDistance = distances[nodeId] !== -Infinity ? distances[nodeId] : g.node(nodeId) || 0;
        if (nodeDistance >= maxDistance) {
            maxDistance = nodeDistance;
            maxNode = nodeId;
        }
    });

    // Reconstruct the path
    const path: string[] = [];
    const visited = new Set<string>(); // To handle cycles or re-visits

    while (maxNode && !visited.has(maxNode)) {
        visited.add(maxNode); // Avoid revisiting nodes
        path.unshift(maxNode); // Add to the front of the path
        maxNode = predecessors[maxNode];
    }

    // Ensure the end node with zero duration is included (if it's the last node)
    if (maxNode && !visited.has(maxNode)) {
        path.unshift(maxNode);
    }

    return { maxDistance, path };
}

export const hasCycle = (graph: dia.Graph): boolean => {
    const g = new graphlib.Graph();

    graph.getElements().forEach((node) => g.setNode(node.id as string));
    graph.getLinks().forEach((link) => {
        const sourceId = link.get('source').id;
        const targetId = link.get('target').id;
        if (sourceId && targetId) {
            g.setEdge(sourceId, targetId);
        }
    });

    return !graphlib.alg.isAcyclic(g);
}


