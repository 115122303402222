import {Accordion} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {Team, Tool} from "../../../interfaces";
import {getTeams} from "../../../api/teams";
import {getTools} from "../../../api/tools";
import {useRecoilState, useRecoilValue} from "recoil";
import {organizationUuidState, processActiveTaskUuid, processTasksState} from "../../../store";
import {TaskListRow} from "./TaskListRow";
import AddTasksPanel from "./AddTasksPanel";
import {useAlerts} from "../../../hooks/useAlerts";

interface TaskListProps {
    editMode: boolean;
    templateUuid: string;
    wideMode: boolean;
    organizationUuid?: string | null;
}

export default function TaskList(props: TaskListProps) {
    const [teams, setTeams] = useState<Team[]>([])
    const [tools, setTools] = useState<Tool[]>([])
    const organizationUuid = useRecoilValue(organizationUuidState)
    const processTasks = useRecoilValue(processTasksState)
    const [activeKey, setActiveKey] = useRecoilState(processActiveTaskUuid)
    const {addError} = useAlerts()
    
    const toggleAccordion = useCallback((key: string) => {
        setActiveKey(activeKey === key ? null : key);
    }, [activeKey, setActiveKey]);

    useEffect(() => {
        getTeams()
            .then(data => setTeams(data))
            .catch(error => addError(error))
    }, [addError, organizationUuid]);

    useEffect(() => {
        getTools()
            .then(data => setTools(data))
            .catch(error => addError(error))
    }, [addError, organizationUuid]);

    return (
        <>
            <Accordion flush activeKey={activeKey} onSelect={(key) => setActiveKey(key as string)}>
                {processTasks.map(task => (
                    <TaskListRow key={task.uuid}
                                 task={task}
                                 teams={teams}
                                 tools={tools}
                                 toggleAccordion={toggleAccordion}
                                 editMode={props.editMode}></TaskListRow>
                ))}
            </Accordion>
            {props.editMode && <AddTasksPanel
                teams={teams}
                tools={tools}
                templateUuid={props.templateUuid}/>}
        </>)
}