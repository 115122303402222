import React from "react"
import {FullContainer} from "./FullContainer"

interface FullContainerProps {
    children: any
    className?: string
}

export function Full75CenteredContainer(props: FullContainerProps) {
    return (<FullContainer className={`w-75 ms-auto me-auto ${props.className}`}>
        {props.children}
    </FullContainer>)
}