import {WizardProcess} from "../interfaces";
import {getRecoil} from "recoil-nexus";
import {organizationUuidState} from "../store";
import fetcher from "./fetcher";

export const updateWizardProcess = async (process: WizardProcess): Promise<{
    wizardProcessUuid: string,
    processUuid: string
}> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('PUT', `/wizard/process/${process.uuid}`, organizationUuid, {
            process: {
                templateUuid: process.templateUuid,
                name: process.name,
                code: process.code,
                teams: process.teams,
                tools: process.tools,
                tasks: process.tasks,
                ...(process.status && {status: process.status})
            }
        });
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getWizardProcess = async (uuid: string): Promise<WizardProcess> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/wizard/process/${uuid}`, organizationUuid).then(resp => resp.process)
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getWizardProcessList = async (): Promise<WizardProcess[]> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/wizard/process/list`, organizationUuid).then(result => result.processes);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const deleteWizardProcess = async (uuid: string): Promise<WizardProcess> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('DELETE', `/wizard/process/${uuid}`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getWizardProcessCodes = async (): Promise<string[]> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/wizard/process/codes`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}


export const createWizardProcess = async (process: WizardProcess): Promise<{
    wizardProcessUuid: string,
    processUuid: string
}> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('POST', '/wizard/process', organizationUuid, {
            process: {
                templateUuid: process.templateUuid,
                name: process.name,
                code: process.code,
                teams: process.teams,
                tools: process.tools,
                tasks: process.tasks
            }
        });
    } catch (e) {
        return Promise.reject(e);
    }
}
