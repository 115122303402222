import {atom, selector} from "recoil";
import {
    AlertMessage,
    ErrorState,
    Organization,
    Process, ProcessTask,
    User,
    WizardArrangeTasks,
    WizardProcess
} from "./interfaces";

export const errorState = atom<ErrorState>({
    key: 'errorState',
    default: {},
});

export const userState = atom<User | null>({
    key: 'userState',
    default: {organizations: [], name: '', email: '', picture: '', resetPossible: false},
});

const localStorageEffect = (key: any) => ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }

    // Listen for changes and persist them to localStorage
    onSet((newValue: any) => {
        if (newValue == null) {
            localStorage.removeItem(key); // Remove if null
        } else {
            localStorage.setItem(key, JSON.stringify(newValue));
        }
    });
};

export const organizationUuidState = atom<string | null>({
    key: 'organizationUuid',
    default: '',
    effects: [localStorageEffect('organizationUuidState')],
})

export const organizationState = selector<Organization | null>({
    key: 'organizationState',
    get: ({get}) => {
        const user = get(userState);
        const organizationUuid = get(organizationUuidState);
        return user?.organizations.find(organization => organization.uuid === organizationUuid) || null;
    },
})

export const wizardProcessState = atom<WizardProcess>({
    key: 'wizardProcess',
    default: {
        name: '',
        code: '',
        uuid: '',
        status: 'PENDING',
        createdBy: '',
        createdAt: '',
        templateUuid: '',
        tasks: [],
        tools: [],
        teams: [],
    }
})

export const wizardArrangeTasksState = selector<WizardArrangeTasks>({
    key: 'wizardArrange',
    get: ({get}) => {
        const wizardProcess = get(wizardProcessState);
        const selected = wizardProcess.tasks?.filter(task => task.selected) || []
        return {
            available: selected?.filter(task => !task.configured),
            configured: selected?.filter(task => task.configured)
        }
    },
    set: ({set, get}, tasks: WizardArrangeTasks) => {
        const wizardProcess = get(wizardProcessState);
        const configured = tasks.configured.map(task => ({...task, configured: true}))
        const available = tasks.available.map(task => ({...task, configured: false}))
        const unselected = wizardProcess.tasks.filter(task => !task.selected).map(task => ({
            ...task,
            configured: false
        }))
        const newState = {
            ...wizardProcess,
            tasks: [...configured, ...available, ...unselected]
        };
        set(wizardProcessState, newState);
    }
});

export const processState = atom<Process>({
    key: 'processState',
    default: {
        name: '',
        code: '',
        description: '',
        uuid: '',
        templateUuid: '',
        process: {
            tasks: [],
            links: [],
            graphMetadata: {}
        },
        createdAt: '',
        createdBy: '',
        updatedAt: '',
        updatedBy: '',
        initialized: false,
    },
})

export const processTopologicalOrderState = atom<string[]>({
    key: 'processTopologicalOrder',
    default: [],
})

export const processTasksState = selector<ProcessTask[]>({
    key: 'processTasksState',
    get: ({get}) => {
        const process = get(processState);
        const order = get(processTopologicalOrderState);

        return process.process.tasks.map(task => ({
            task,
            position: process.process.graphMetadata[task.uuid]?.position || {x: 0, y: 0}
        })).sort((a, b) => a.position.y - b.position.y)
            .sort((a, b) => {
                const indexA = order.indexOf(a.task.uuid) !== -1 ? order.indexOf(a.task.uuid) : a.position.y;
                const indexB = order.indexOf(b.task.uuid) !== -1 ? order.indexOf(b.task.uuid) : b.position.y;
                return indexA - indexB;
            }).map(obj => obj.task)
    },
    set: ({set, get}, tasks: ProcessTask[]) => {
        const oldProcess = get(processState);
        const newState = {
            ...oldProcess,
            process: {
                ...oldProcess.process,
                tasks: tasks,
            },
        };
        set(processState, newState);
    }
});

export const processViewEditModeState = atom<boolean>({
    key: 'processViewEditModeState',
    default: false,
})

export const processActiveTaskUuid = selector<string | null>({
    key: 'processActiveTaskUuid',
    get: ({get}) => {
        const process = get(processState);

        return process.process.tasks.find(task => task.active)?.uuid || null;
    },
    set: ({set, get}, uuid: string) => {
        const oldProcess = get(processState);
        const newState = {
            ...oldProcess,
            process: {
                ...oldProcess.process,
                tasks: oldProcess.process.tasks.map(task => {
                    if (task.uuid === uuid) {
                        return {...task, active: true}
                    } else if (task.active) {
                        return {...task, active: false}
                    } else {
                        return task;
                    }
                })
            },
        };
        set(processState, newState);
    }
})

export const resettingGraphState = atom<boolean>({
    key: 'resettingGraphState',
    default: false
})

export const alertsState = atom<AlertMessage[]>({
    key: 'alertsState',
    default: []
})