import {Button} from "react-bootstrap";
import {ArrowPathIcon} from "@heroicons/react/16/solid";
import React, {useState} from "react";

export interface SaveButtonProps {
    handleSave: (done: () => void) => void;
    variant?: string
    title?: string;
}

export const SaveButton = (props: SaveButtonProps) => {
    const [saving, setSaving] = useState(false)

    const handleSave = () => {
        setSaving(true);
        props.handleSave(() => {
            const interval = setInterval(() => {
                setSaving(false)
                clearInterval(interval)
            }, 1000)
        })
    }

    return (<Button size="sm" variant={props.variant ? props.variant : 'outline-danger'}
                    style={{minWidth: '60px'}}
                    onClick={() => handleSave()}>
        <div className="d-flex flex-row justify-content-center align-items-center">
            <span>{props.title ? props.title : 'Save'}</span> {saving && <ArrowPathIcon className="ms-1 icon-style-sm rotating"/>}</div>
    </Button>)
}