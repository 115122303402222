import React from "react";
import {ProjectTask} from "../../interfaces";
import {ProjectSubtaskRow} from "./ProjectSubtaskRow";
import {Col, Row} from "react-bootstrap";

interface ProjectSubtaskListProps {
    task: ProjectTask,
    reloadState: () => void
}

export const ProjectSubtaskList = (props: ProjectSubtaskListProps) => {

    return (

        <div>
            <Row className="text-strong">
                <Col md={2}>
                    Done
                </Col>
                <Col md={3}>
                    Name
                </Col>
                <Col>
                    Completed
                </Col>
            </Row>

            {props.task.subtasks.map((subtask) => (
                <ProjectSubtaskRow key={subtask.uuid} reloadState={props.reloadState} subtask={subtask}/>
            ))}
        </div>

    )
}