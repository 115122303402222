import {Button} from "react-bootstrap";
import React from "react";
import SortableItem from "./SortableItem";
import DroppableElement from "./DroppableElement";

export default function DroppableButtonContainer({id, items, activeId, onClick}: {
    items: string[],
    activeId: string | null
    id: string,
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}) {

    return (
        <DroppableElement items={items} id={id}>
            <div className="d-flex flex-row flex-wrap">
                {items.map((item) => (
                    <SortableItem key={`button_${item}`} id={item}
                                  invisible={activeId === item}>
                        <Button className="me-3 mt-3"
                                onClick={onClick}
                                value={item}
                                variant="outline-primary">
                            {item}
                        </Button>
                    </SortableItem>
                ))}
            </div>
        </DroppableElement>
    );
};