import _ from "lodash";
import {getRecoil} from "recoil-nexus";
import {Project} from "../interfaces";
import {organizationUuidState} from "../store";
import fetcher from "./fetcher";

export interface CreateProjectRequest {
    projectName: string;
    startDate: string;
    ownerUuid: string;
    dailyValue: number;
    description: string;
    processUuid: string;
}

export const saveProject = async (request: CreateProjectRequest): Promise<{ uuid: string }> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('POST', `/project`, organizationUuid, request)
    } catch (e) {
        return Promise.reject(e);
    }
}

export interface UpdateProjectRequest {
    projectUuid: string;
    projectName?: string;
    startDate?: string;
    dailyValue?: number;
    description?: string;
}

export const updateProject = async (request: UpdateProjectRequest): Promise<{ uuid: string }> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('POST', `/project/${request.projectUuid}`, organizationUuid, {
            projectName: request.projectName,
            startDate: request.startDate,
            dailyValue: request.dailyValue,
            description: request.description,
        })
    } catch (e) {
        return Promise.reject(e);
    }
}

export const resumeProject = async (projectUuid: string): Promise<{ uuid: string }> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('POST', `/project/${projectUuid}/resume`, organizationUuid)
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getProject = async (uuid: string): Promise<{ project: Project }> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/project/${uuid}`, organizationUuid)
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getProjectList = async (): Promise<{ projects: Project[] }> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/project/list`, organizationUuid)
    } catch (e) {
        return Promise.reject(e);
    }
}

export interface UpdateProjectTaskRequest {
    taskUuid: string;
    finishedDate?: string | null;
    description?: string;
    handoverData?: any;
    skip?: boolean;
}

export const updateProjectTask = async (request: UpdateProjectTaskRequest): Promise<{ uuid: string }> => {
    const organizationUuid = getRecoil(organizationUuidState);
    const cleanedRequest = _.pickBy({
        finishedDate: request.finishedDate,
        description: request.description,
        handoverData: request.handoverData,
        skip: request.skip
    }, value => value !== undefined);
    try {
        return await fetcher('POST', `/project/task/${request.taskUuid}`, organizationUuid, cleanedRequest)
    } catch (e) {
        return Promise.reject(e);
    }
}

export interface UpdateProjectSubtaskRequest {
    subtaskUuid: string;
    finishedDate?: string | null;
    skip?: boolean;
}

export const updateProjectSubtask = async (request: UpdateProjectSubtaskRequest): Promise<{ uuid: string }> => {
    const organizationUuid = getRecoil(organizationUuidState);
    const cleanedRequest = _.pickBy({
        finishedDate: request.finishedDate,
        skip: request.skip
    }, value => value !== undefined);
    try {
        return await fetcher('POST', `/project/subtask/${request.subtaskUuid}`, organizationUuid, cleanedRequest)
    } catch (e) {
        return Promise.reject(e);
    }
}