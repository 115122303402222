import React, {useMemo, useState} from "react";
import {SidebarLink} from "./SidebarLink";
import {Nav} from "react-bootstrap";
import {SidebarOrganizationSelect} from "./SidebarOrganizationSelect";
import {
    Bars3Icon,
    BarsArrowUpIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    DocumentDuplicateIcon,
    DocumentIcon,
    HomeIcon,
    SparklesIcon
} from "@heroicons/react/24/outline";
import {useRecoilValue} from "recoil";
import {userState} from "../../store";
import {PresentationChartLineIcon} from "@heroicons/react/16/solid";

export const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(false);
    const user = useRecoilValue(userState)
    const visible = useMemo(() => !!user?.organizations?.length, [user])

    return (<>
        {visible &&
            (<div className="sticky-div"
                  style={{
                      width: collapsed ? '60px' : '200px',
                      minWidth: collapsed ? '60px' : '200px',
                      transition: 'width 0.3s',
                      background: '#f8f9fa',
                      height: '100vh',
                      whiteSpace: 'nowrap',
                  }}
            >
                <button
                    style={{
                        margin: '10px',
                        marginLeft: '12px',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    {collapsed ?
                        <ChevronDoubleRightIcon className="icon-style link-secondary"/> :
                        <ChevronDoubleLeftIcon className="icon-style link-secondary"/>
                    }
                </button>
                <Nav className="flex-column">
                    <SidebarOrganizationSelect collapsed={collapsed}/>
                    <SidebarLink iconComponent={HomeIcon} label="Home" to="/home" collapsed={collapsed}/>
                    <SidebarLink iconComponent={Bars3Icon} label="Processes" to="/process/list" collapsed={collapsed}/>
                    <SidebarLink iconComponent={SparklesIcon} label="New Process" to="/wizard/tasks"
                                 collapsed={collapsed}/>
                    {//<SidebarLink iconComponent={BarsArrowUpIcon} label="Upload" to="/upload" collapsed={collapsed}/>
                    }
                    <SidebarLink iconComponent={DocumentDuplicateIcon} label="Projects" to="/projects"
                                 collapsed={collapsed}/>
                    <SidebarLink iconComponent={DocumentIcon} label="New Project" to="/new-project" collapsed={collapsed}/>
                    {///<SidebarLink iconComponent={PresentationChartLineIcon} label="Analytics" to="/analytics" collapsed={collapsed}/>
                    }
                </Nav>
            </div>)
        }</>)
};