import React from "react";
import {Title} from "../../components/Title";
import {useRecoilValue} from "recoil";
import {userState} from "../../store";
import {UserInvitations} from "../../components/UserInvitations";
import {Full75CenteredContainer} from "../../components/containers/Full75CenteredContainer";

export const PendingInvitationsView = () => {

    const user = useRecoilValue(userState);

    return (<>
        <Full75CenteredContainer>
            <Title title="Pending invitations" subtitle={`${user?.email}`} className="mb-5"/>
            <UserInvitations/>
        </Full75CenteredContainer>
    </>)
}