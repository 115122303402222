import {ProcessLink, ProcessTask} from "../../../interfaces";

const findRelatedTasks = (taskUuid: string, tasks: ProcessTask[], links: ProcessLink[]): {
    dependsOn: ProcessTask[],
    dependedBy: ProcessTask[]
} => {
    const taskMap = new Map<string, ProcessTask>(tasks.map(task => [task.uuid, task]))
    const dependsOn: ProcessTask[] = [];
    const dependedBy: ProcessTask[] = []
    links.forEach(link => {
        if (link?.targetTaskUuid === taskUuid) {
            const sourceTask = taskMap.get(link.sourceTaskUuid);
            if (sourceTask) {
                dependsOn.push(sourceTask)
            }
        }
        if (link?.sourceTaskUuid === taskUuid) {
            const targetTask = taskMap.get(link.targetTaskUuid);
            if (targetTask) {
                dependedBy.push(targetTask)
            }
        }
    })
    return {dependedBy, dependsOn}
}

export {findRelatedTasks}