import {Col, Form, FormControl, Row} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import WizardItemSelect from "../WizardItemSelect";
import {useRecoilState, useResetRecoilState} from "recoil";
import {wizardProcessState} from "../../../store";
import {useNavigate, useParams} from "react-router";
import {ProcessTemplate} from "../../../interfaces";
import WizardFrame from "../WizardFrame";
import ItemAddModal from "../ItemAddModal";
import {Title} from "../../../components/Title";
import WizardProgress from "../WizardProgress";
import {Section} from "../../../components/Section";
import initials from "initials";
import {useAlerts} from "../../../hooks/useAlerts";
import {createWizardProcess, getWizardProcess, getWizardProcessCodes, updateWizardProcess} from "../../../api/wizard";
import {getTemplates, saveTemplate} from "../../../api/templates";

export default function WizardTasks() {
    const [templates, setTemplates] = useState<ProcessTemplate[]>([])
    const [wizardProcess, setWizardProcess] = useRecoilState(wizardProcessState)
    const resetWizardProcess = useResetRecoilState(wizardProcessState)
    const navigate = useNavigate();
    const params = useParams();
    const {addError} = useAlerts();
    const [codes, setCodes] = useState<string[]>([])
    const formRef = useRef<HTMLFormElement>(null);
    const [valid, setValid] = useState<boolean>(false)

    useEffect(() => {
        if (params.uuid) {
            getWizardProcess(params.uuid)
                .then(data => setWizardProcess(data))
                .catch(error => addError(error))
        }
    }, [addError, params.uuid, setWizardProcess]);

    useEffect(() => {
        getWizardProcessCodes()
            .then(data => setCodes(data))
            .catch(addError)
    }, [addError]);

    useEffect(() => {
        navigate(`/wizard/tasks/${wizardProcess.uuid}`)
    }, [navigate, wizardProcess.uuid]);

    useEffect(() => {
        getTemplates().then(data => {
            setTemplates(data)
            if (!wizardProcess?.templateUuid) {
                const template = data[0];
                setWizardProcess(prev => ({
                    ...prev,
                    templateUuid: template.uuid,
                    tasks: template.tasks,
                    tools: template.tools,
                    teams: template.teams
                }))
            }
        }).catch(error => addError(error))
    }, [addError, setWizardProcess, wizardProcess?.templateUuid]);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWizardProcess(prev => ({
            ...prev,
            name: event.currentTarget.value,
        }))
    }

    const generateNewCode = (candidate: string): string => {
        if (!codes.includes(candidate)) {
            return candidate;
        }
        const newCode = candidate.substring(0, 5) + Math.floor(Math.random() * 10);
        return generateNewCode(newCode);
    }

    const handleNameBlur = () => {
        if (wizardProcess.name) {
            setWizardProcess(prev => {
                const newCode = generateNewCode(initials(prev.name).substring(0, 6).toUpperCase())
                return ({
                    ...prev,
                    code: !prev.code ? newCode : prev.code
                });
            })
        }
    }

    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWizardProcess(prev => ({
            ...prev,
            code: event.currentTarget.value?.toUpperCase()
        }))
    }

    const handleSelectTemplateUuid = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const templateUuid = event.target.value as string;
        const template = templates.find(template => template.uuid === templateUuid)
        setWizardProcess(prev => ({
            ...prev,
            templateUuid: templateUuid,
            tasks: template?.tasks || [],
            tools: template?.tools || [],
            teams: template?.teams || []
        }))
    }

    useEffect(() => setValid(formRef!.current!.checkValidity()
            && wizardProcess.tasks?.some(task => task.selected))
        , [wizardProcess.tasks, wizardProcess.name, wizardProcess.code, wizardProcess.templateUuid]);

    const handleClickNext = () => {
        if(!valid) {
            formRef.current!.reportValidity()
            return;
        }
        if (wizardProcess.uuid) {
            updateWizardProcess(wizardProcess)
                .then(_ => navigate(`/wizard/teams-and-tools/${wizardProcess.uuid}`))
                .catch(error => addError(error))
        } else {
            createWizardProcess(wizardProcess)
                .then(data => data.wizardProcessUuid)
                .then(uuid => navigate(`/wizard/teams-and-tools/${uuid}`))
                .catch(error => addError(error))
        }
    }

    const handleAddTemplate = (name: string) => {
        saveTemplate(name).then(resp => {
            const newTemplate = {uuid: resp.uuid, name, tasks: [], tools: [], teams: []}
            setTemplates(prev => [...prev, newTemplate])
            setWizardProcess(prev => ({
                ...prev,
                templateUuid: resp.uuid,
                tasks: [],
                tools: [],
                teams: []
            }))
        }).catch(error => addError(error))
    }

    return (
        <WizardFrame next={handleClickNext}
                     prev={() => resetWizardProcess()}
                     prevLabel={'Reset'}

        >
            <div className="d-flex flex-row justify-content-between">
                <Title title="Let's get started ..." className="me-auto mb-3"/>
                <div className="mt-2">
                    <WizardProgress/>
                </div>
            </div>

            <Section>
                <Form ref={formRef} onSubmit={handleClickNext}>
                    <Row>
                        <Col md={4} className="d-flex flex-row align-items-center">
                            <strong>Process name</strong>
                        </Col>
                        <Col md={8}>
                            <FormControl
                                value={wizardProcess.name}
                                type="text"
                                required={true}
                                minLength={3}
                                maxLength={100}
                                onBlur={handleNameBlur}
                                onChange={handleNameChange}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={4} className="d-flex flex-row align-items-center">
                            <strong>Project code</strong>
                        </Col>
                        <Col md={8}>
                            <FormControl
                                value={wizardProcess.code}
                                type="text"
                                pattern={'[A-Z0-9]*'}
                                required={true}
                                minLength={2}
                                maxLength={7}
                                onChange={handleCodeChange}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={4} className="d-flex flex-row align-items-center">
                            <strong>Template</strong>
                        </Col>
                        <Col md={8}>
                            <div className="d-flex flex-row">
                                <Form.Select className="me-3"
                                             value={wizardProcess.templateUuid}
                                             onChange={handleSelectTemplateUuid}>
                                    {templates.map((item, index) => (
                                        <option key={`template_${index}`} value={item.uuid}>{item.name}</option>
                                    ))}
                                </Form.Select>
                                <ItemAddModal itemType="template" handleNewItem={handleAddTemplate}></ItemAddModal>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Section>


            <Section className="mt-3" title="Which of these tasks apply to your process?">
                <WizardItemSelect itemType="tasks"></WizardItemSelect>
            </Section>
        </WizardFrame>
    )
}