import React, {useEffect, useState} from "react";
import {getProcess} from "../../api/process";
import {useParams} from "react-router";
import {Process} from "../../interfaces";
import {Col, Row} from "react-bootstrap";
import {FullContainer} from "../../components/containers/FullContainer";
import {ProcessGraph} from "./graph/ProcessGraph";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {organizationUuidState, processState, processViewEditModeState,} from "../../store";
import TaskList from "./table/TaskList";
import "jspdf-autotable";
import {ProcessViewMenu} from "./ProcessViewMenu";
import {useAlerts} from "../../hooks/useAlerts";

export default function ProcessView() {
    const params = useParams()
    const [process, setProcess] = useRecoilState<Process>(processState)
    const resetProcess = useResetRecoilState(processState)
    const organizationUuid = useRecoilValue(organizationUuidState)
    const [viewMode, setViewMode] = useState<'graph' | 'table' | 'both'>('both')
    const editMode = useRecoilValue<boolean>(processViewEditModeState)
    const [triggerPdfExport, setTriggerPdfExport] = useState<((() => void) | null)>(null)
    const {addError} = useAlerts();

    useEffect(() => {
        return () => {
            resetProcess()
        }
    }, [resetProcess]);

    useEffect(() => {
        if (params.uuid) {
            getProcess(params.uuid)
                .then(response => {
                    setProcess(response);
                })
                .then(_ => setViewMode('both'))
                .catch(error => addError(error))
        }
    }, [params.uuid, organizationUuid, setProcess]);

    const graphHideStyle = {
        width: viewMode === 'table' ? '0px' : undefined,
        height: viewMode === 'table' ? '0px' : undefined
    }

    return (<FullContainer>
        <ProcessViewMenu processUuid={params.uuid} triggerPdfExport={triggerPdfExport} setViewMode={setViewMode} viewMode={viewMode}/>
        <Row className="d-flex justify-content-center">
            {<Col style={graphHideStyle} md={viewMode === 'both' ? 6 : 12}>
                <ProcessGraph registerTriggerPdfExport={setTriggerPdfExport}
                              hide={viewMode === 'table'}
                              editMode={editMode}></ProcessGraph>
            </Col>}
            {viewMode !== 'graph' && <Col md={viewMode === 'both' ? 6 : 8}>
                <TaskList
                    wideMode={viewMode === 'table'}
                    editMode={editMode}
                    templateUuid={process.templateUuid}
                    organizationUuid={organizationUuid}/>
            </Col>}
        </Row>
    </FullContainer>)
}
