import {organizationUuidState} from '../store';
import {Process, WizardProcess} from '../interfaces';
import {getRecoil} from 'recoil-nexus';
import fetcher from "./fetcher";

export const getProcessList = async (): Promise<Process[]> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/process/list`, organizationUuid)
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getProcess = async (uuid: string): Promise<Process> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/process/${uuid}`, organizationUuid)
    } catch (e) {
        return Promise.reject(e);
    }
}

export const saveProcess = async (process: Process): Promise<void> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('POST', `/process/${process.uuid}`, organizationUuid, {
            process: process.process,
            name: process.name,
            code: process.code
        })
    } catch (e) {
        return Promise.reject(e);
    }
}

export const deleteProcess = async (uuid: string): Promise<WizardProcess> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('DELETE', `/process/${uuid}`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}







