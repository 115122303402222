import {Dropdown, Nav} from "react-bootstrap";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import React from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {organizationState, organizationUuidState, userState} from "../../store";
import {useNavigate} from "react-router";
import {ArrowsRightLeftIcon} from "@heroicons/react/24/solid";

interface SidebarOrganizationSelectProps {
    collapsed: boolean;
}

export const SidebarOrganizationSelect = ({collapsed}: SidebarOrganizationSelectProps) => {
    const user = useRecoilValue(userState)
    const navigate = useNavigate();
    const setOrganizationUuid = useSetRecoilState(organizationUuidState);
    const organization = useRecoilValue(organizationState);

    const style = {
        padding: '8px 0 8px 16px',

    }

    return (
        <Dropdown style={style}>
            <Dropdown.Toggle as={Nav.Link} className="d-flex align-items-center link-secondary">
                <ArrowsRightLeftIcon style={{width: '24px', marginRight: collapsed ? 0 : '10px'}}/>
                {!collapsed && <span className="text-truncate" style={{maxWidth: "130px"}}>{organization?.name}</span>}
            </Dropdown.Toggle>
            <Dropdown.Menu className=".sidebar-dropdown">
                {user?.organizations?.map(org => (
                    <Dropdown.Item key={org.uuid}
                                   onClick={() => setOrganizationUuid(org.uuid)}>{org.name}
                    </Dropdown.Item>
                ))}
                <Dropdown.Item onClick={() => navigate("/setup-organization")}
                               className="d-flex align-items-center">
                    <PlusIcon className="me-1 icon-style"/> Add new</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}