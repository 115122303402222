import {ProcessSubtask, ProcessTask, Team} from "../../../interfaces";
import {useSortable} from "@dnd-kit/sortable";
import {useSetRecoilState} from "recoil";
import {processTasksState} from "../../../store";
import React, {useEffect, useRef} from "react";
import {Form} from "react-bootstrap";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import {NumericFormat, SourceInfo} from "react-number-format";

interface SubtaskListRowProps {
    subtask: ProcessSubtask
    index: number,
    editMode: boolean,
    teams: Team[]
    task: ProcessTask,
}

export const SubtaskListRow = ({
                               subtask, index, editMode, teams, task
                           }: SubtaskListRowProps) => {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: subtask.uuid});
    const inputRef = useRef<HTMLInputElement>(null);

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : 'none',
        transition: transition ?? 'transform 150ms ease',
        cursor: editMode ? 'move' : 'pointer'
    };
    const setProcessTasks = useSetRecoilState(processTasksState);
    const handleTeamSelect = (event: React.ChangeEvent<HTMLSelectElement>, subtaskUuid: string) => {
        event.stopPropagation();
        const teamUuid = event.currentTarget.value;
        const foundTeam = teams.find(team => team?.uuid === teamUuid)!;
        setProcessTasks(prev =>
            prev.map(prevTask => prevTask.uuid === task.uuid ?
                {
                    ...prevTask,
                    subtasks: prevTask.subtasks?.map(subtask => subtask.uuid === subtaskUuid ? {...subtask, team: foundTeam} : subtask)
                } : prevTask))
    }
    const handleJobNameChange = (event: React.ChangeEvent<any>, subtaskUuid: string) => {
        event.stopPropagation();
        const name = event.currentTarget.value;
        setProcessTasks(prev =>
            prev.map(prevTask => prevTask.uuid === task.uuid ?
                {
                    ...prevTask,
                    subtasks: prevTask.subtasks?.map(subtask => subtask.uuid === subtaskUuid ? {...subtask, name} : subtask)
                } : prevTask))
    }
    const handleDurationChange = (duration: number | undefined = 0, sourceInfo: SourceInfo, subtaskUuid: string) => {
        const event = sourceInfo.event;
        event?.preventDefault();
        event?.stopPropagation();
        if (duration > task.duration) {
            event?.currentTarget.setCustomValidity("Cannot exceed parent task duration")
        } else {
            event?.currentTarget.setCustomValidity('')
        }
        event?.currentTarget.reportValidity();
        setProcessTasks(prev =>
            prev.map(prevTask => prevTask.uuid === task.uuid ?
                {
                    ...prevTask,
                    subtasks: prevTask.subtasks?.map(subtask => subtask.uuid === subtaskUuid ? {...subtask, duration} : subtask)
                } : prevTask))
    }
    useEffect(() => {
        let current = inputRef.current;
        if (subtask.duration > task.duration) {
            current?.setCustomValidity("Cannot exceed parent task duration")
        } else {
            current?.setCustomValidity('')
        }
        current?.checkValidity()
    }, [subtask.duration, task.duration]);

    const handleRemoveJob = () => {
        setProcessTasks(prev =>
            prev.map(prevTask => prevTask.uuid === task.uuid ?
                {
                    ...prevTask,
                    subtasks: prevTask.subtasks?.filter(x => x.uuid !== subtask.uuid)
                } : prevTask))
    };
    return (
        <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <td>
                <span>{index + 1}</span>
            </td>
            <td>
                <Form.Control
                    type="input"
                    className={'plain-input-sm text-truncate' + (editMode ? 'pe-4' : '')}
                    disabled={!editMode}
                    data-no-dnd="true"
                    onChange={e => handleJobNameChange(e, subtask.uuid)}
                    value={subtask?.name}
                ></Form.Control>
            </td>
            <td>
                <NumericFormat
                    getInputRef={inputRef}
                    className="plain-input-sm"
                    suffix=" days"
                    decimalScale={0}
                    required
                    disabled={!editMode}
                    value={subtask.duration}
                    onValueChange={(duration, sourceInfo) => handleDurationChange(duration.floatValue, sourceInfo, subtask.uuid)}
                    displayType="input"
                    allowNegative={false}
                    allowLeadingZeros={false}
                />
            </td>
            <td>
                <Form.Select onChange={e => handleTeamSelect(e, subtask.uuid)}
                             className={'plain-input-sm pointer text-truncate' + (editMode ? 'pe-4' : '')}
                             disabled={!editMode}
                             data-no-dnd="true"
                             onClick={e => e.stopPropagation()}
                             value={subtask?.team?.uuid}>
                    {teams.map(team => (
                        <option key={team?.uuid} value={team?.uuid}>{team.name}</option>
                    ))}
                </Form.Select>
            </td>

            <td>{editMode && <XMarkIcon className="icon-style-sm" onClick={handleRemoveJob}></XMarkIcon>}</td>
        </tr>)
}