import React from 'react';
import ReactDOM from 'react-dom/client';
import './custom.scss';
import {RouterProvider,} from "react-router-dom";
import {RecoilRoot} from "recoil";
import RecoilNexus from "recoil-nexus";
import {router} from "./router";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
        <RecoilRoot>
            <RecoilNexus/>
            <RouterProvider router={router}/>
        </RecoilRoot>
);


