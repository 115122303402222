import React from "react";
import {Alert} from "react-bootstrap";

interface DismissibleAlertProps {
    children: any;
    onClose: () => void;
    variant: 'danger' | 'success' | 'info' | 'warning';
}

export const DismissibleAlert = ({children, onClose, variant}: DismissibleAlertProps) => {
    return (
        <Alert variant={variant} dismissible onClose={onClose}>
            <span>
                {children || 'An error has occurred'}
            </span>
        </Alert>
    );
}
