import React, {useEffect, useState} from "react";
import {Process, WizardProcess} from "../../interfaces";
import {useNavigate} from "react-router";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {organizationUuidState, wizardProcessState} from "../../store";
import {ProcessListRow} from "./ProcessListRow";
import {Title} from "../../components/Title";
import {Section} from "../../components/Section";
import {Table} from "react-bootstrap";
import {Full75CenteredContainer} from "../../components/containers/Full75CenteredContainer";
import {useAlerts} from "../../hooks/useAlerts";
import {deleteWizardProcess, getWizardProcess, getWizardProcessList} from "../../api/wizard";
import {deleteProcess, getProcessList} from "../../api/process";

export default function ProcessListView() {

    const [wizardProcessList, setWizardProcessList] = useState<WizardProcess[]>([])
    const [processList, setProcessList] = useState<Process[]>([])
    const setWizardProcess = useSetRecoilState<WizardProcess>(wizardProcessState)
    const organizationUuid = useRecoilValue(organizationUuidState)
    const navigate = useNavigate();
    const resetProcess = useResetRecoilState(wizardProcessState)
    const {addError} = useAlerts();

    useEffect(() => {
        getWizardProcessList()
            .then(result => setWizardProcessList(result || []))
            .catch(error => addError(error))
    }, [organizationUuid]);

    useEffect(() => {
        getProcessList()
            .then(result => setProcessList(result || []))
            .catch(error => addError(error))
    }, [organizationUuid]);

    const handleSelect = (process: WizardProcess) => {
        if (process.status === 'PENDING') {
            getWizardProcess(process.uuid).then(data => {
                setWizardProcess(data)
                navigate(`/wizard/tasks`)
            })
        } else {
            navigate(`/process/${process.uuid}`);
        }
    }

    const handleDelete = (process: WizardProcess) => {
        if (process.status) {
            deleteWizardProcess(process.uuid)
                .then(_ => getWizardProcessList())
                .then(result => setWizardProcessList(result || []))
                .catch(error => addError(error))

        } else {
            deleteProcess(process.uuid)
                .then(_ => getProcessList())
                .then(result => setProcessList(result || []))
                .catch(error => addError(error))
        }

    }

    return (<>
        <Full75CenteredContainer>
            <Title title="Processes" className="mb-5"/>
            <Section>
                <Table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Updated by</th>
                        <th>Updated at</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {[...processList, ...wizardProcessList]?.map(process =>
                        <ProcessListRow key={process.uuid} process={process} onDelete={handleDelete}
                                        onSelect={handleSelect}></ProcessListRow>
                    )}
                    <tr>
                        <td colSpan={6} onClick={() => {
                            resetProcess();
                            navigate('/wizard/tasks');
                        }} className="pointer text-center text-muted">Add a new process</td>
                    </tr>
                    </tbody>
                </Table>

            </Section>
        </Full75CenteredContainer>
    </>)
}

