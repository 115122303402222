import {Button, Col, FormControl, InputGroup, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {getUser, resetLoginPassword, updateUser} from "../../api/users";
import {useRecoilState} from "recoil";
import {userState} from "../../store";
import EnvelopeIcon from "@heroicons/react/24/solid/EnvelopeIcon";
import {CloudArrowUpIcon} from "@heroicons/react/16/solid";
import {useAlerts} from "../../hooks/useAlerts";
import {HoverTooltip} from "../../components/HoverTooltip";

export const EditUserProfile = () => {
    const [user, setUser] = useRecoilState(userState)
    const [userName, setUserName] = useState<string>('')
    const inputRef = useRef<HTMLInputElement>(null);
    const {addError} = useAlerts()

    useEffect(() => {
        setUserName(user?.name || '')
    }, [user?.name]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        setUserName(value)
    }

    const hasChanges = user?.name !== userName;

    const handleResetPassword = () => {
        resetLoginPassword().catch(error => addError(error))
    }

    const handleSaveName = () => {
        if (!inputRef.current?.checkValidity()) {
            inputRef.current?.reportValidity();
            return;
        }
        updateUser({name: userName})
            .then(_ => getUser())
            .then(user => setUser(user))
            .catch(error => addError(error))
    }

    return (<>
            <Row>
                <Col md={4}>
                    <Row>
                        <Col>
                            <InputGroup>
                                <FormControl type="text"
                                             name="name"
                                             data-form-type="other"
                                             data-lpignore="true"
                                             value={userName}
                                             ref={inputRef}
                                             maxLength={50}
                                             minLength={2}
                                             required={true}
                                             placeholder="Full name"
                                             onKeyDown={e => (e.key === 'Enter') && handleSaveName()}
                                             onChange={onNameChange}></FormControl>
                                <Button variant="secondary"
                                        disabled={!hasChanges}
                                        onClick={handleSaveName}
                                >
                                    <CloudArrowUpIcon className="icon-style"/>
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <HoverTooltip hidden={user!.resetPossible}
                                      text="Only users with password can perform reset">
                            <div className="mt-3">
                                <Button variant="secondary"
                                        disabled={!user?.resetPossible}
                                        onClick={handleResetPassword}>
                                    Reset password <EnvelopeIcon className="icon-style"></EnvelopeIcon>
                                </Button>
                            </div>
                        </HoverTooltip>
                    </Row>
                </Col>
            </Row></>
    )
}