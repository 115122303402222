import fetcher from "./fetcher";
import {User} from "../interfaces";
import {getRecoil} from "recoil-nexus";
import {organizationUuidState} from "../store";

export const updateUser = async ({name}: { name: string }): Promise<void> => {
    try {
        return await fetcher('POST', `/user/update`, null, {name});
    } catch (e) {
        return Promise.reject(e);
    }
}

export const resetLoginPassword = async (): Promise<void> => {
    try {
        return await fetcher('POST', `/user/reset-password`);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getUser = async (): Promise<User> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('GET', '/user/info', organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}
