import {Table} from "react-bootstrap";
import {PendingInvitationRow} from "../routes/pending-invitations/PendingInvitationRow";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {organizationUuidState} from "../store";
import {PendingInvitation} from "../interfaces";
import {useAlerts} from "../hooks/useAlerts";
import {acceptInvitation, declineInvitation, deleteInvitation, getInvitations} from "../api/invitations";

interface UserInvitationsParams {
    title?: string;
}

export const UserInvitations = ({title}: UserInvitationsParams) => {
    const [invitations, setInvitations] = useState<PendingInvitation[]>([]);
    const setOrganizationUuid = useSetRecoilState(organizationUuidState);
    const {addError} = useAlerts()

    useEffect(() => {
        getInvitations()
            .then(data => setInvitations(data))
            .catch(error => addError(error))
    }, [addError]);

    const handleAccept = (invitation: PendingInvitation) => {
        acceptInvitation(invitation.uuid!)
            .then(_ => getInvitations())
            .then(data => setInvitations(data))
            .then(_ => setOrganizationUuid(invitation.organizationUuid!))
            .catch(error => addError(error))
    }

    const handleDecline = (invitation: PendingInvitation) => {
        declineInvitation(invitation.uuid!)
            .then(_ => getInvitations())
            .then(data => setInvitations(data))
            .catch(error => addError(error))
    }

    const handleDelete = (invitation: PendingInvitation) => {
        deleteInvitation(invitation.uuid!)
            .then(_ => getInvitations())
            .then(data => setInvitations(data))
            .catch(error => addError(error))
    }

    return (
        <>
            {invitations?.length > 0 && (<div className="mt-3">
                    {title && <h4 className="mb-3">{title}</h4>}
                    <Table>
                        <thead>
                        <tr>
                            <th>Organization</th>
                            <th>Team</th>
                            <th>Role</th>
                            <th>Invited by</th>
                            <th>When</th>
                            <th>Status</th>
                            <th className="text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {invitations?.map(invitation =>
                            <PendingInvitationRow key={invitation.uuid}
                                                  invitation={invitation}
                                                  handleAccept={handleAccept}
                                                  handleDelete={handleDelete}
                                                  handleDecline={handleDecline}/>
                        )}
                        </tbody>
                    </Table>
                </div>
            )}
        </>
    );
}