import {ProcessSubtask, ProcessTask, Team} from "../../../interfaces";
import {Form, FormControl, Table} from "react-bootstrap";
import React, {FormEvent, useState} from "react";
import {useSetRecoilState} from "recoil";
import {processTasksState} from "../../../store";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {arrayMove, SortableContext, sortableKeyboardCoordinates} from "@dnd-kit/sortable";
import {MouseSensor} from "../../wizard/arrange/MouseSensor";
import {SubtaskListRow} from "./SubtaskListRow";

interface SubtaskListProps {
    task: ProcessTask,
    editMode: boolean,
    teams: Team[],
}

export const SubtaskList = ({task, teams, editMode}: SubtaskListProps) => {
    const sensors = useSensors(
        useSensor(MouseSensor, {activationConstraint: {distance: 5}}),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const setProcessTasks = useSetRecoilState(processTasksState)
    const [subtaskNameInput, setSubtaskNameInput] = useState<string | null>(null)
    const [subtaskInputOpen, setSubtaskInputOpen] = useState<boolean>(false)
    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event;
        if (over && active.id !== over.id) {
            const oldIndex = task.subtasks?.findIndex((subtask: ProcessSubtask) => subtask.uuid === active.id) || 0;
            const newIndex = task.subtasks?.findIndex((subtask: ProcessSubtask) => subtask.uuid === over.id) || 0;
            setProcessTasks(prev =>
                prev.map(prevTask => prevTask.uuid === task.uuid ?
                    {
                        ...prevTask,
                        subtasks: arrayMove(prevTask.subtasks, oldIndex, newIndex)
                    } : prevTask))
        }
    };
    const handleJobAdd = (event: FormEvent) => {
        event.preventDefault();
        const newJob: ProcessSubtask = {
            description: "",
            duration: 0,
            name: subtaskNameInput || '',
            team: task.team,
            uuid: crypto.randomUUID()
        }
        setSubtaskNameInput(null)
        setProcessTasks(prev =>
            prev.map(prevTask => prevTask.uuid === task.uuid ?
                {
                    ...prevTask,
                    subtasks: [...(prevTask.subtasks || []), newJob]
                } : prevTask))
    }
    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            {(task.subtasks || editMode) && (<Table size="sm" borderless={false} rules="rows" className="mt-3">
                <thead>
                <tr>
                    <th>
                        No
                    </th>
                    <th>
                        Subtask
                    </th>
                    <th>
                        SLA
                    </th>
                    <th>
                        Team
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <SortableContext disabled={!editMode} items={(task.subtasks || []).map((subtask) => subtask.uuid)}>
                    {task.subtasks?.map((subtask, index) => (
                        <SubtaskListRow key={subtask.uuid}
                                        subtask={subtask}
                                        index={index}
                                        editMode={editMode}
                                        teams={teams}
                                        task={task}/>))}
                </SortableContext>
                {editMode && (<tr>
                    <td className="text-center" colSpan={5}>
                        <Form onSubmit={handleJobAdd} className="validated">
                            <FormControl
                                onSubmit={handleJobAdd}
                                type="input"
                                onFocus={() => setSubtaskInputOpen(true)}
                                onBlur={() => setSubtaskInputOpen(false)}
                                {...(subtaskInputOpen ? {required: true, minLength: 3, maxLength: 40} : {})}
                                value={subtaskNameInput || ''}
                                onChange={e => setSubtaskNameInput(e.currentTarget.value)}
                                className="plain-input-sm"
                                plaintext
                                placeholder="Add a subtask and press enter"
                            ></FormControl>
                        </Form>
                    </td>
                </tr>)}
                </tbody>
            </Table>)
            }
        </DndContext>
    )
}
