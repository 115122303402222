import {Accordion, AccordionItem, Col, Row} from "react-bootstrap";
import React, {useState} from "react";
import {Project} from "../../interfaces";
import {ProjectTaskRow} from "./ProjectTaskRow";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";

interface ProjectTaskListProps {
    project: Project
    reloadState: () => void;
}

export const ProjectTaskList = ({project, reloadState}: ProjectTaskListProps) => {

    const [activeKeys, setActiveKeys] = useState<AccordionEventKey>([]);

    const handleToggle = (key: string) => {
        setActiveKeys((prevActiveKeys: string[]) =>
            prevActiveKeys.includes(key)
                ? prevActiveKeys.filter((item) => item !== key)
                : [...prevActiveKeys, key]
        );
    };

    return (
        <Accordion flush activeKey={activeKeys} onSelect={handleToggle}>
            <AccordionItem eventKey={'none'} className="accordion-list-header">
                <Accordion.Header className="narrow-header">
                    <Row className="w-100 ms-1 text-strong">
                        <Col md={4}>
                            Name
                        </Col>
                        <Col md={3}>
                            Status
                        </Col>
                        <Col md={2}>
                            Due
                        </Col>
                        <Col md={2}>
                            Finished
                        </Col>
                    </Row>
                </Accordion.Header>
            </AccordionItem>
            {project.tasks?.map(task => (
                <ProjectTaskRow key={task.uuid}
                                task={task}
                                reloadState={reloadState}

                />
            ))}
        </Accordion>
    )
}