import {Process, WizardProcess} from "../../interfaces";
import {formatDate} from "../../utils/DateUtils";
import React from "react";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import {PROCESS_STATUS} from "../../utils/Constants";

interface ProcessListRowProps {
    process: WizardProcess | Process,
    onSelect: (process: WizardProcess | Process) => void,
    onDelete: (process: WizardProcess | Process) => void
}

export const ProcessListRow = (props: ProcessListRowProps) => {
    const formattedDate = formatDate(props.process.updatedAt!)

    const getStatus = (process: WizardProcess | Process) => {
        const status = (process as WizardProcess).status! || 'COMPLETE';
        return PROCESS_STATUS[status]
    }

    function onClick() {
        props.onSelect(props.process)
    }

    function onDeleteClick(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
        props.onDelete(props.process)
    }

    return (
        <tr onClick={onClick} className="pointer">
            <td>{props.process.name}</td>
            <td>{props.process.code}</td>
            <td>{props.process.updatedBy}</td>
            <td>{formattedDate}</td>
            <td>{getStatus(props.process)}</td>
            <td onClick={onDeleteClick}><XMarkIcon className="icon-style ms-auto link-danger"/></td>
        </tr>
    )
}