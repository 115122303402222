import {GraphMetadata, ProcessLink, ProcessTask} from "../../../interfaces";
import {shapes, util} from "@joint/core";

const CELL_WIDTH = 180;
const CELL_HEIGHT = 120;


const mapToGraphElement = (task: ProcessTask, metadata: GraphMetadata): shapes.standard.Rectangle => {
    return new shapes.standard.Rectangle({
        id: task.uuid,
        size: {width: CELL_WIDTH, height: CELL_HEIGHT},
        position: metadata[task.uuid]?.position || {x: 0, y: 0},
        type: 'standard.Rectangle',
        attrs: {
            body: {
                width: 'calc(w)',
                height: 'calc(h)',
                stroke: task.active ? '#ffceb4' : 'black',
                strokeWidth: task.active ? '2px' : '2px',
            },
            foreignObject: {
                width: 'calc(w-20)',
                height: 'calc(h-20)',
                x: 10,
                y: 10,
            },
            title: {
                fontSize: '5px',
                html: task.name,
            },
            team: {
                html: `<strong>Team</strong>: ${task.team?.name}`
            },
            tool: {
                html: `<strong>Tool</strong>: ${task.tool?.name}`
            }
        },
        markup: util.svg/* xml */`
            <rect @selector="body"/>
            <foreignObject @selector="foreignObject">
                <div
                    xmlns="http://www.w3.org/1999/xhtml"
                    @selector="background"
                    style="display:flex;flex-direction: column;align-items: flex-start;justify-content: space-between;width: 100%;height: 100%;font-family: Arial, Helvetica, sans-serif;">
                    <div @selector="title" style="font-size: 14px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 100%"></div>
                    <div @selector="team" style="font-size: 14px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 100%"></div>
                    <div @selector="tool" style="font-size: 14px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 100%"></div>
                </div>
            </foreignObject>`
    });
}

function mapToGraphLinks(link: ProcessLink): shapes.standard.Link {
    return new shapes.standard.Link({
        id: crypto.randomUUID(),
        source: {id: link.sourceTaskUuid},
        target: {id: link.targetTaskUuid},
        attrs: {
            line: {
                stroke: 'black',
            }
        },
        type: 'standard.Link',
        z: 1
    });
}

export {mapToGraphElement, mapToGraphLinks}