import React, {useState} from "react";
import {Button, Col, Form, FormControl, Row, Table} from "react-bootstrap";
import {setupOrganization} from "../../api/organizations";
import {useNavigate} from "react-router";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {organizationUuidState, userState} from "../../store";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import {Invitation, NullableOption, SelectOption} from "../../interfaces";
import {TEAM_OPTIONS} from "../../utils/Constants";
import {Full75CenteredContainer} from "../../components/containers/Full75CenteredContainer";
import {useAlerts} from "../../hooks/useAlerts";

const animatedComponents = makeAnimated();
const customComponents = {
    ...animatedComponents,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
};

export function SetupOrganizationView() {

    const [organizationName, setOrganizationName] = useState('')
    const [invites, setInvites] = useState<Invitation[]>([]);
    const [teams, setTeams] = useState<NullableOption[]>([]);
    const navigate = useNavigate()
    const user = useRecoilValue(userState)
    const setOrganizationUuid = useSetRecoilState(organizationUuidState)
    const firstOrganization = !user?.organizations?.length
    const [userTeam, setUserTeam] = useState<NullableOption>(null)
    const {addError} = useAlerts();

    const handleAddInvite = () => {
        setInvites([...invites, {email: '', team: null, role: {value: 'MEMBER', label: 'User'}}]);
    };

    const handleRemoveInvite = (index: number) => {
        const newInvites = invites.filter((_, i) => i !== index);
        setInvites(newInvites);
    };

    const handleInviteChange = (index: number, invite: Partial<{ email: string, team: SelectOption, role: SelectOption }>) => {
        const newInvites = [...invites];
        newInvites[index] = {...newInvites[index], ...invite}
        setInvites(newInvites);
    }

    const onFormSubmit = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
        event.stopPropagation();
        event.preventDefault();
        const request = {
            organizationName,
            userTeam: userTeam?.value!,
            teams: teams.filter(team => !!team).map(team => team!.value),
            invitations: invites.map(invite => ({
                email: invite.email,
                role: invite.role.value,
                team: invite.team?.value!
            }))
        }
        setupOrganization(request)
            .then(resp => resp.uuid)
            .then(organizationUuid => setOrganizationUuid(organizationUuid))
            .then(_ => navigate("/home"))
            .catch(error => addError(error))
    }

    const roleOptions = [
        {value: 'MEMBER', label: 'User'},
        {value: 'OWNER', label: 'Admin'}
    ];

    const plainSelect = {
        control: (provided: any) => ({
            ...provided,
            border: "none",
            background: "transparent",
            boxShadow: "none",
            minHeight: "auto",
            padding: 0,
            width: "auto",
            fontSize: "16px"
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            padding: 4,
            color: "#000",
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
    };

    const handleTeamsChange = (newTeams: NullableOption[]) => {
        setTeams(newTeams)
        setUserTeam(prev => newTeams.includes(prev) ? prev : null)
        setInvites(prev => prev.map(invite => !newTeams.includes(invite.team) ? {...invite, team: null} : invite))
    };
    return (
        <Full75CenteredContainer>
            <h2>{firstOrganization ? 'Welcome to ProcessPlot!' : 'Set up an organization'}</h2>
            <div className="mt-2">
                    <span>
                        {firstOrganization ? 'Before you start building your first process please set up your account.' : ''}
                        </span>
            </div>
            <Form className="mt-5" onSubmit={onFormSubmit}>
                <Row>
                    <Col md={4} className="d-flex flex-row align-items-center">
                        <strong>Organization name</strong>
                    </Col>
                    <Col md={8}>
                        <FormControl
                            value={organizationName}
                            type="text"
                            maxLength={80}
                            minLength={5}
                            required={true}
                            onChange={e => setOrganizationName(e.currentTarget.value)}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={4}>
                        <strong>Teams</strong>
                    </Col>
                    <Col md={8}>
                        <CreatableSelect
                            onKeyDown={e => e.key === "Enter" && e.stopPropagation()}
                            className="w-100"
                            noOptionsMessage={() => "Type to add more"}
                            options={TEAM_OPTIONS}
                            components={customComponents}
                            closeMenuOnSelect={false}
                            isMulti={true}
                            onChange={handleTeamsChange}
                            value={teams}
                        />
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col><strong>Add people to your organization</strong></Col>
                </Row>
                <Row className="mt-3">
                    <Col md={12}>
                        <Table>
                            <thead>
                            <tr>
                                <td>
                                    Email
                                </td>
                                <td>
                                    Team
                                </td>
                                <td>
                                    Permissions
                                </td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="align-middle">
                                    {user?.email} <span className="text-muted">(you)</span>
                                </td>
                                <td className="align-middle">
                                    <Select
                                        className="plain-input"
                                        value={userTeam}
                                        options={teams}
                                        styles={plainSelect}
                                        required={true}
                                        components={animatedComponents}
                                        onChange={setUserTeam}
                                    />
                                </td>
                                <td className="align-middle">
                                    <Select
                                        className="plain-input"
                                        value={{value: 'OWNER', label: 'Admin'}}
                                        isDisabled={true}
                                        styles={plainSelect}
                                        components={animatedComponents}
                                    />
                                </td>
                                <td className="align-middle">
                                    <div className="d-flex justify-content-center align-items-center"
                                         style={{cursor: "pointer"}}><XMarkIcon className="icon-style"/>
                                    </div>
                                </td>
                            </tr>
                            {invites.map((invite, index) => (
                                <tr key={index} className="mb-3">
                                    <td className="align-middle">
                                        <Form.Control
                                            plaintext
                                            data-form-type="other"
                                            data-lpignore="true"
                                            className="plain-input"
                                            type="email"
                                            required={true}
                                            autoComplete="false"
                                            value={invite.email}
                                            onChange={(event) => handleInviteChange(index, {email: event.currentTarget.value})}
                                            placeholder={`example@example.com`}
                                        />
                                    </td>
                                    <td className="align-middle">
                                        <Select
                                            className="plain-input"
                                            value={invite.team}
                                            options={teams}
                                            required={true}
                                            styles={plainSelect}
                                            components={animatedComponents}
                                            onChange={(team: SelectOption) => handleInviteChange(index, {team})}
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            className="plain-input"
                                            value={invite.role}
                                            options={roleOptions}
                                            styles={plainSelect}
                                            required={true}
                                            components={animatedComponents}
                                            onChange={(role: SelectOption) => handleInviteChange(index, {role})}
                                        />
                                    </td>
                                    <td className="align-middle">
                                        <div className="d-flex justify-content-center align-items-center"
                                             style={{cursor: "pointer"}}><XMarkIcon className="icon-style"
                                                                                    onClick={() => handleRemoveInvite(index)}/>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={4}>
                                    <div className="text-muted pointer d-flex flex-row justify-content-center">
                                        <span onClick={handleAddInvite}>Click to add people to invite</span>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>

                </Row>
                <Row className="mt-5">
                    <Col md={12} className="d-flex flex-row justify-content-center">
                        <Button
                            type="submit"
                            onSubmit={onFormSubmit}
                        >All done!</Button>
                    </Col>
                </Row>
            </Form>
        </Full75CenteredContainer>
    );
}