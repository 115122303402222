import React, {useEffect, useState} from "react";
import {Button, Container, Row} from "react-bootstrap";
import {getInvitation} from "../api/invitations";
import {useParams} from "react-router";
import {PendingInvitation} from "../interfaces";
import {useAlerts} from "../hooks/useAlerts";

export function UserInvitationView() {

    const params = useParams()
    const [invitation, setInvitation] = useState<PendingInvitation | null>(null);
    const {addError} = useAlerts();

    useEffect(() => {
        if (params.uuid) {
            getInvitation(params.uuid)
                .then(data => setInvitation(data))
                .catch(error => addError(error))
        }
    }, [addError, params.uuid]);

    const handleSignIn = () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/login`;
    };

    return (<>
        <Container className="d-flex flex-column justify-content-center align-items-center" style={{minHeight: '70vh'}}>
            {invitation?.status === 'PENDING' ?
                (<>
                    <Row className="w-75 d-flex justify-content-center">
                        <span>You (<strong>{invitation?.email}</strong>) have been invited to join <strong>{invitation?.organizationName}</strong> in ProcessPlot. Please sign in to continue.</span>
                    </Row>
                    <Row className="d-flex justify-content-center mt-3">
                        <Button onClick={handleSignIn}>Sign in</Button>
                    </Row>
                </>) : (
                    <>
                        <Row className="w-75 d-flex justify-content-center">
                            <span>The invitation link is expired.</span>
                        </Row>
                    </>
                )}
        </Container>
    </>)
}