import {dia} from "@joint/core";
import {hasCycle} from "./PathUtils";
import React from "react";

export const connectionValidator = (graphRef: React.MutableRefObject<dia.Graph | null>, addError: (error: any) => void) => {
    return (cellViewS: dia.CellView, magnetS: SVGElement, cellViewT: dia.CellView, magnetT: SVGElement, end: dia.LinkEnd, linkView: dia.LinkView) => {
        if (cellViewT === cellViewS) {
            return false;
        }
        if (!cellViewT || cellViewT.model.isLink()) {
            return false;
        }
        const {sourceId, targetId} = {sourceId: cellViewS.model.id, targetId: cellViewT.model.id};
        const existingLink = graphRef.current!.getLinks().find(link => {
            let linkSourceId = link.source().id;
            let linkTargetId = link.target().id;
            return (linkSourceId === sourceId && linkTargetId === targetId) || (linkSourceId === targetId && linkTargetId === sourceId);
        });

        if (existingLink) {
            return false;
        }

        // Temporarily add the new link to the graph to check for cycles
        const tempLink = new dia.Link({source: {id: sourceId}, target: {id: targetId}, type: 'standard.Link'});
        graphRef.current!.addCell(tempLink);

        const isCyclic = hasCycle(graphRef.current!);
        if (isCyclic) {
            addError({error: 'Process cannot have circular dependencies!', id: 'cyclic_graph_alert'})
        }
        // Remove the temporary link
        tempLink.remove();

        return !isCyclic;
    }
}