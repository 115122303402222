import {Title} from "../../components/Title";
import React, {useEffect, useState} from "react";
import {Full75CenteredContainer} from "../../components/containers/Full75CenteredContainer";
import {Process, Project, WizardProcess} from "../../interfaces";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {organizationUuidState, wizardProcessState} from "../../store";
import {useNavigate} from "react-router";
import {useAlerts} from "../../hooks/useAlerts";
import {deleteWizardProcess, getWizardProcess, getWizardProcessList} from "../../api/wizard";
import {deleteProcess, getProcessList} from "../../api/process";
import {Section} from "../../components/Section";
import {Table} from "react-bootstrap";
import {ProcessListRow} from "../process-list/ProcessListRow";
import {getProjectList} from "../../api/projects";
import {ProjectListRow} from "./ProjectListRow";

export const ProjectListView = () => {

    const [projectList, setProjectList] = useState<Project[]>([])
    const organizationUuid = useRecoilValue(organizationUuidState)
    const navigate = useNavigate();
    const {addError} = useAlerts();

    useEffect(() => {
        getProjectList()
            .then(result => setProjectList(result.projects || []))
            .catch(error => addError(error))
    }, [addError, organizationUuid]);

    const handleSelect = (project: Project) => {
        navigate(`/project/${project.uuid}`);
    }

    return (<>
        <Full75CenteredContainer>
            <Title title="Projects" className="mb-5"/>
            <Section>
                <Table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {[...projectList]?.map(project =>
                        <ProjectListRow key={project.uuid} project={project}
                                        onSelect={handleSelect}></ProjectListRow>
                    )}
                    <tr>
                        <td colSpan={6} onClick={() => {
                            navigate('/new-project');
                        }} className="pointer text-center text-muted">Add a new project
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Section>
        </Full75CenteredContainer>
    </>)
}