import {dia, shapes} from "@joint/core";
import {useCallback, useEffect, useRef, useState} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import {processState, processTopologicalOrderState, resettingGraphState} from "../../../store";
import {mapToGraphElement, mapToGraphLinks} from "./MapperUtil";
import debounce from 'lodash-es/debounce';
import Cell = dia.Cell;
import {rankNodes} from "./PathUtils";

export const useGraph = (onGraphChange: (needsLayouting: boolean) => void) => {
    const graphRef = useRef<dia.Graph | null>(null);
    const [process, setProcess] = useRecoilState(processState)
    const [initialLoad, setInitialLoad] = useState(true)
    const [resettingGraph, setResettingGraph] = useRecoilState(resettingGraphState)
    const setProcessTopologicalOrder = useSetRecoilState(processTopologicalOrderState)
    const debouncedFunctionsRef = useRef(new Map<string, Function>());

    const getDebouncedFunction = useCallback((key: string, func: (...args: any) => void, wait: number) => {
        if (!debouncedFunctionsRef.current.has(key)) {
            debouncedFunctionsRef.current.set(key, debounce(func, wait, {
                trailing: true,
                maxWait: 25,
                leading: true
            }));
        }
        return debouncedFunctionsRef.current.get(key);
    }, []);

    const handleGraphRemove = useCallback((cell: Cell) => {
        if (cell.isLink() && (cell.target().id && cell.source().id)) {
            setProcess(prev => ({
                ...prev,
                process: {
                    ...prev.process,
                    links: prev.process.links.filter(link => !(link.sourceTaskUuid === cell.source().id
                        && link.targetTaskUuid === cell.target().id))
                }
            }))
        } else if (cell.isElement()) {
            setProcess(prev => ({
                ...prev,
                process: {
                    ...prev.process,
                    links: prev.process.links.filter(link =>
                        link.sourceTaskUuid === cell.id
                        || link.targetTaskUuid === cell.id),
                    tasks: prev.process.tasks.filter(task => task.uuid !== cell.id)
                }
            }))
        }
    }, [setProcess])

    const updateElementPosition = useCallback((cell: Cell) => {
        setProcess(prev => ({
            ...prev,
            process: {
                ...prev.process,
                graphMetadata: {
                    ...prev.process.graphMetadata,
                    [cell.id]: {position: {x: cell.position().x, y: cell.position().y}},
                    layoutSet: prev.process.graphMetadata.layoutSet
                }
            }
        }))
    }, [setProcess])

    const handleGraphChange = useCallback((cell: Cell) => {
        if (cell.isLink() && cell.get('target').id) {
            setProcess(prev => {
                return prev.process.links.some(link => link.sourceTaskUuid === cell.source().id && link.targetTaskUuid === cell.target().id) ? prev : ({
                    ...prev,
                    process: {
                        ...prev.process,
                        links: [...prev.process.links, {
                            sourceTaskUuid: cell.source()!.id as string,
                            targetTaskUuid: cell.target()!.id as string,
                        }]
                    }
                })
            })
        } else if (cell.isElement()) {
            getDebouncedFunction(cell.id as any, updateElementPosition, 25)!(cell)
        }
    }, [getDebouncedFunction, setProcess, updateElementPosition])

    useEffect(() => {
        graphRef.current = new dia.Graph({}, {
            cellNamespace: {
                ...shapes
            }
        });
        graphRef.current!.on('add', handleGraphChange)
        graphRef.current!.on('remove', handleGraphRemove)
        graphRef.current!.on('change', handleGraphChange)
        setProcess(prev => ({...prev}))

        return () => {
            graphRef.current!.off('add', handleGraphChange)
            graphRef.current!.off('remove', handleGraphRemove)
            graphRef.current!.off('change', handleGraphChange)
        }
    }, [handleGraphChange, handleGraphRemove, setProcess]);

    /**
     * Update graph from process
     */
    useEffect(() => {
        const links = process.process.links.map(link => mapToGraphLinks(link));
        const tasks = process.process.tasks.map(task => mapToGraphElement(task, process.process.graphMetadata));

        graphRef.current?.startBatch('update')
        try {
            if (initialLoad && tasks.length) {
                graphRef.current?.addCells([...tasks, ...links]);
                setInitialLoad(false)
            } else {
                if (resettingGraph) {
                    graphRef.current?.getLinks().forEach(link => link.remove())
                    graphRef.current?.resetCells([])
                }
                tasks.forEach(task => {
                    const element = graphRef.current?.getCell(task.id)
                    if (!element) {
                        graphRef.current?.addCell(task)
                    } else {
                        element.attr('title', task.attr('title'))
                        element.attr('team', task.attr('team'))
                        element.attr('tool', task.attr('tool'))
                        element.attr('body', task.attr('body'))
                        element.attr('position', task.position)
                    }
                })
                if (resettingGraph) {
                    graphRef.current?.addCells(links)
                    setResettingGraph(false);
                }

                const linkMap = new Map(graphRef.current?.getLinks()
                    .map(link => [link.source().id + "_" + link.target().id, link]))
                links.forEach(link => {
                    const edge = linkMap.get(link.source().id + "_" + link.target().id)
                    edge?.attr('line', link.attr('line'))
                })
            }


            let layoutSet = false;
            if (process.process.tasks.length > 0) {
                layoutSet = process.process.graphMetadata['layoutSet']
                setProcess(prev => ({
                    ...prev, process: {
                        ...prev.process,
                        graphMetadata: {
                            ...prev.process.graphMetadata,
                            layoutSet: true
                        }
                    }
                }))
            }
            onGraphChange(!layoutSet)
        } finally {
            setProcessTopologicalOrder(rankNodes(graphRef.current!))
            graphRef.current?.stopBatch('update')
        }
        // do NOT set metadata as dependency here as it leads to cyclical state update
    }, [process.process.tasks, process.process.links]);

    return {
        graphRef,
    }
}




