import React from "react";
import {Col, Row} from "react-bootstrap";

interface TitleProps {
    title: any;
    subtitle?: string;
    className: string;
    children?: React.ReactNode;
}

export const Title = ({title, subtitle, className, children}: TitleProps) => {
    return (
        <div className={`${className}`}>
            <Row>
                <Col md={children ? 6 : 12}>
                    <h2><strong>{title}</strong></h2>
                    {subtitle && (<h5 className="text-muted">{subtitle}</h5>)}
                </Col>
                <Col md={children ? 6 : 0}>
                    {children}
                </Col>
            </Row>
        </div>
    )
}