import fetcher from "./fetcher";
import {OrganizationMember, PendingInvitation, SetupOrganizationRequest} from "../interfaces";
import {getRecoil} from "recoil-nexus";
import {organizationUuidState} from "../store";

export const leaveOrganization = async (uuid: string): Promise<void> => {
    try {
        return await fetcher('POST', `/organization/${uuid}/leave`, uuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const deleteOrganization = async (uuid: string): Promise<void> => {
    try {
        return await fetcher('DELETE', `/organization/${uuid}`, uuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getOrganizationMembers = async (): Promise<OrganizationMember[]> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/organization/${organizationUuid}/members`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const removeOrganizationMember = async (userUuid: string): Promise<void> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('DELETE', `/organization/${organizationUuid}/member/${userUuid}`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getOrganizationInvitations = async (): Promise<PendingInvitation[]> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/organization/${organizationUuid}/invitations`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const setupOrganization = async (request: SetupOrganizationRequest): Promise<{
    uuid: string
}> => {
    try {
        return await fetcher('POST', `/organization/setup`, null, request);
    } catch (e) {
        return Promise.reject(e);
    }
}
