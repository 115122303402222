import {useNavigate} from "react-router";
import {Col, Row} from "react-bootstrap";
import React from "react";

interface SettingsLinkProps {
    className?: string
    icon: any;
    label: string;
    onClick?: () => void;
    to?: string;
    toggleSettings: (showSettings: boolean) => void;
}

export const SettingsLink = (props: SettingsLinkProps) => {
    const navigate = useNavigate();

    const doAction = () => {
        if (props.onClick) {
            props.onClick();
        } else if (props.to) {
            navigate(props.to);
        }
        props.toggleSettings(false);
    }
    return (
        <>
            <Row className={`${props.className} mb-2`} onClick={doAction} style={{cursor: "pointer"}}>
                <Col xs={2} md={2} className="ms-3 d-flex align-items-center">
                    {props.icon}
                </Col>
                <Col className="ps-0">{props.label}</Col>
            </Row>
        </>
    )
}