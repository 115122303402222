import React, {useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {useNavigate} from "react-router";
import {Title} from "../components/Title";
import {Full75CenteredContainer} from "../components/containers/Full75CenteredContainer";


export default function HomeView() {
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false);

    return (
        <Full75CenteredContainer className="h-100">
            <Title title="Home" className="mb-5"/>
            <div className="d-flex justify-content-center align-items-center h-50">
                <Row>
                    <Col md={6}>
                        <Row className="m-1">
                            <Button onClick={() => setShowModal(true)} variant="primary" className="p-3">
                                <strong>Create new process</strong>
                            </Button>
                        </Row>
                        <Row className="m-2">
                            <p style={{fontSize: '0.75rem'}} className="text-center">Jump into process wizard or
                                upload an
                                existing process
                                description to get started.</p>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row className="m-1">
                            <Button variant="outline-primary" onClick={() => navigate('/process/list')}
                                    className="p-3">
                                <strong>Open saved process</strong>
                            </Button>
                        </Row>
                        <Row className="m-2">
                            <p style={{fontSize: '0.75rem'}} className="text-center">Open a previously saved
                                process to
                                modify or view</p>
                        </Row>
                    </Col>
                </Row>
            </div>

            <Modal className="h-75 modal-lg" centered show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Body className="m-3">
                    <Row className="m-2 justify-content-center">
                        <Col className="col-5">
                            <Row className="m-1">
                                <Button onClick={() => navigate("/wizard/tasks")} variant="outline-primary"
                                        className="p-3">
                                    <strong>Go to wizard</strong>
                                </Button>
                            </Row>
                        </Col>
                        <Col className="col-5">
                            <Row className="m-1">
                                <Button onClick={() => navigate("/upload")} variant="outline-primary"
                                        className="p-3">
                                    <strong>Upload a description</strong>
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </Full75CenteredContainer>
    );
}