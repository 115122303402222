import {Project} from "../../interfaces";
import {PROJECT_STATUS} from "../../utils/Constants";
import React from "react";

interface ProjectListRowProps {
    project: Project,
    onSelect: (process: Project) => void,
}

export const ProjectListRow = (props: ProjectListRowProps) => {

    const getStatus = (project: Project) => {
        return PROJECT_STATUS[project.status]
    }

    function onClick() {
        props.onSelect(props.project)
    }

    return (
        <tr onClick={onClick} className="pointer">
            <td>{props.project.name}</td>
            <td>{props.project.code}</td>
            <td>{getStatus(props.project)}</td>
        </tr>
    )
}