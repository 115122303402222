export const ROLES: { [key: string]: string } = {
    OWNER: "Admin",
    MEMBER: "User",
};

export const INVITATION_STATUSES = {
    EXPIRED: "Expired",
    PENDING: "Pending",
    CANCELLED: "Revoked",
    COMPLETE: "Accepted",
    DECLINED: "Declined",
};

export const TASK_STATUS = {
    ON_TRACK: "On track",
    NOT_STARTED: "Not started",
    DELAYED: "Delayed",
    COMPLETED: "Completed",
    SKIPPED: "Skipped"
};

export const PROCESS_STATUS = {
    PENDING: "Draft",
    COMPLETE: "Complete",
};

export const PROJECT_STATUS = {
    NOT_STARTED: "Not started",
    ON_HOLD: "On hold",
    ON_TRACK: "On track",
    DELAYED: "Delayed",
    COMPLETED: "Completed",
    CANCELLED: "Cancelled",
};

export const TEAM_OPTIONS = [
    {label: "Engineering", value: "Engineering"},
    {label: "Design", value: "Design"},
    {label: "Sales", value: "Sales"},
    {label: "Business development", value: "Business development"},
    {label: "Finance", value: "Finance"},
    {label: "Operations", value: "Operations"},
    {label: "Product", value: "Product"},
    {label: "Marketing", value: "Marketing"},
    {label: "Legal", value: "Legal"},
    {label: "Compliance", value: "Compliance"},
    {label: "Customer success", value: "Customer success"},
    {label: "Customer support", value: "Customer support"},
    {label: "Integrations support", value: "Integrations support"},
];