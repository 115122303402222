import React from "react"
import {Container} from "react-bootstrap"

interface CenterContainerProps {
    children: any
}

export function CenterContainer(props: CenterContainerProps) {

    return (<>
        <Container className="d-flex justify-content-center align-items-center" style={{minHeight: '70vh'}}>
                {props.children}
        </Container></>)
}