import {Invitation, PendingInvitation} from "../interfaces";
import fetcher from "./fetcher";
import {getRecoil} from "recoil-nexus";
import {organizationUuidState} from "../store";

export const getInvitation = async (uuid: string): Promise<PendingInvitation> => {
    try {
        return await fetcher('GET', `/invitation/${uuid}`);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getInvitations = async (): Promise<PendingInvitation[]> => {
    try {
        return await fetcher('GET', `/invitation/list`);
    } catch (e) {
        return Promise.reject(e);
    }
}


export const acceptInvitation = async (uuid: string): Promise<void> => {
    try {
        return await fetcher('PUT', `/invitation/${uuid}/accept`);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const declineInvitation = async (uuid: string): Promise<void> => {
    try {
        return await fetcher('PUT', `/invitation/${uuid}/decline`);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const cancelInvitation = async (uuid: string): Promise<void> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('PUT', `/invitation/${uuid}/cancel`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const createInvitation = async (invitation: Invitation): Promise<void> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('POST', `/invitation`, organizationUuid, {
            email: invitation.email,
            team: invitation.team?.value,
            role: invitation.role.value,
        });
    } catch (e) {
        return Promise.reject(e);
    }
}

export const deleteInvitation = async (uuid: string): Promise<void> => {
    try {
        return await fetcher('DELETE', `/invitation/${uuid}`);
    } catch (e) {
        return Promise.reject(e);
    }
}