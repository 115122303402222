import {Table} from "react-bootstrap";
import {MemberRow} from "./MemberRow";
import React from "react";
import {OrganizationMember} from "../../interfaces";
import {Section} from "../../components/Section";
import {useRecoilValue} from "recoil";
import {userState} from "../../store";
import {useAlerts} from "../../hooks/useAlerts";
import {getOrganizationMembers, removeOrganizationMember} from "../../api/organizations";

interface ActiveUsersParams {
    organizationUuid?: string,
    isOwner: boolean,
    members: OrganizationMember[],
    setMembers: (value: any) => void
}

export const ActiveUsersList = ({isOwner, members, setMembers}: ActiveUsersParams) => {

    const user = useRecoilValue(userState);
    const {addError} = useAlerts()

    const handleDeleteMember = (member: OrganizationMember) => {
        removeOrganizationMember(member.uuid!)
            .then(_ => getOrganizationMembers())
            .then(data => setMembers(data))
            .catch(error => addError(error))
    }

    return (<Section title="Active users">
        <Table>
            <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Team</th>
                <th>Role</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {members.map(member => <MemberRow
                key={member.uuid}
                member={member}
                deleteAllowed={isOwner}
                isCurrentUser={user?.email === member.email}
                handleDelete={handleDeleteMember}></MemberRow>)}
            </tbody>
        </Table>
    </Section>)
}