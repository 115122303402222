import {Title} from "../components/Title";
import React from "react";
import {Full75CenteredContainer} from "../components/containers/Full75CenteredContainer";

export const AnalyticsView = () => {
    return (
        <Full75CenteredContainer>
            <Title title={"Analytics"} className="mb-5"/>
        </Full75CenteredContainer>
    )
}