import {formatDate} from "../../utils/DateUtils";
import React from "react";
import CheckIcon from "@heroicons/react/24/solid/CheckIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import {INVITATION_STATUSES, ROLES} from "../../utils/Constants";
import {PendingInvitation} from "../../interfaces";

interface PendingInvitationRowProps {
    invitation: PendingInvitation,
    handleAccept: (invitation: PendingInvitation) => void,
    handleDecline: (invitation: PendingInvitation) => void,
    handleDelete: (invitation: PendingInvitation) => void,
}

export const PendingInvitationRow = ({
                                         invitation,
                                         handleDecline,
                                         handleAccept,
                                         handleDelete
                                     }: PendingInvitationRowProps) => {
    return (
        <tr>
            <td className="align-middle">
                {invitation?.organizationName}
            </td>
            <td className="align-middle">
                {invitation.team.name}
            </td>
            <td className="align-middle">
                {ROLES[invitation!.role]}
            </td>
            <td className="align-middle">
                {invitation.invitedBy}
            </td>
            <td className="align-middle">
                {formatDate(invitation.createdAt!)}
            </td>
            <td className="align-middle">
                {INVITATION_STATUSES[invitation.status]}
            </td>
            <td className="align-middle">
                <div className="d-flex justify-content-between align-items-center"
                     style={{cursor: "pointer"}}>
                    {invitation.status === 'PENDING' &&
                        <XMarkIcon className="icon-style m-auto link-danger"  onClick={() => handleDecline(invitation)}/>
                    }
                    {invitation.status === 'PENDING' &&
                        <CheckIcon className="icon-style m-auto link-success" onClick={() => handleAccept(invitation)}/>
                    }
                    {invitation.status !== 'PENDING' &&
                        <XMarkIcon className="icon-style m-auto link-danger" onClick={() => handleDelete(invitation)}/>
                    }
                </div>
            </td>
        </tr>
    )
}