import {getRecoil} from "recoil-nexus";
import {organizationUuidState} from "../store";
import fetcher from "./fetcher";

export const saveTool = async (name: string) => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('POST', `/tool`, organizationUuid, {name});
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getTools = async () => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('GET', `/tools`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}


