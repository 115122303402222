import React from "react";
import {Title} from "../components/Title";
import {Full75CenteredContainer} from "../components/containers/Full75CenteredContainer";

export default function UploadView() {

    return (
        <Full75CenteredContainer>
            <Title title="Create process via upload" className="mb-5"/>
            <div>Uploader functionality will be included here</div>
        </Full75CenteredContainer>
    )
}