import {createBrowserRouter} from "react-router-dom";
import ErrorView from "./routes/ErrorView";
import HomeView from "./routes/HomeView";
import React from "react";
import UploadView from "./routes/UploadView";
import WizardTasksView from "./routes/wizard/tasks/WizardTasks";
import WizardTeamsAndToolsView from "./routes/wizard/teams-and-tools/WizardTeamsAndTools";
import WizardArrangeView from "./routes/wizard/arrange/WizardArrange";
import LandingPageView from "./routes/LandingView";
import NavigationGuard from "./components/NavigationGuard";
import ProcessView from "./routes/process-view/ProcessView";
import ProcessListView from "./routes/process-list/ProcessListView";
import {SetupOrganizationView} from "./routes/setup-organization/SetupOrganizationView";
import {PendingInvitationsView} from "./routes/pending-invitations/PendingInvitationsView";
import {UserInvitationView} from "./routes/UserInvitationView";
import {UserProfileView} from "./routes/profile/UserProfileView";
import {OrganizationSettingsView} from "./routes/organization-settings/OrganizationSettingsView";
import {UnverifiedView} from "./routes/UnverifiedView";
import {ManageUsersView} from "./routes/manage-users/ManageUsersView";
import {AnalyticsView} from "./routes/AnalyticsView";
import {ProjectListView} from "./routes/project-list/ProjectListView";
import { NewProjectView } from "./routes/NewProjectView";
import {ProjectView} from "./routes/project-view/ProjectView";
import App from "./App";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                errorElement: <ErrorView/>,
                children: [
                    {
                        path: "landing",
                        element: <LandingPageView/>,
                    },
                    {
                        path: "home",
                        element: <NavigationGuard><HomeView/></NavigationGuard>,
                    },
                    {
                        path: "wizard/tasks",
                        element: <NavigationGuard><WizardTasksView/></NavigationGuard>,
                    },
                    {
                        path: "wizard/tasks/:uuid",
                        element: <NavigationGuard><WizardTasksView/></NavigationGuard>,
                    },
                    {
                        path: "wizard/teams-and-tools/:uuid",
                        element: <NavigationGuard><WizardTeamsAndToolsView/></NavigationGuard>,
                    },
                    {
                        path: "wizard/arrange/:uuid",
                        element: <NavigationGuard><WizardArrangeView/></NavigationGuard>,
                    },
                    {
                        path: "upload",
                        element: <NavigationGuard><UploadView/></NavigationGuard>,
                    },
                    {
                        path: "process/:uuid",
                        element: <NavigationGuard><ProcessView/></NavigationGuard>,
                    },
                    {
                        path: "process/list",
                        element: <NavigationGuard><ProcessListView/></NavigationGuard>,
                    },
                    {
                        path: "setup-organization",
                        element: <NavigationGuard><SetupOrganizationView/></NavigationGuard>,
                    },
                    {
                        path: "invitation/:uuid",
                        element: <UserInvitationView/>,
                    },
                    {
                        path: "pending-invitations",
                        element: <NavigationGuard><PendingInvitationsView/></NavigationGuard>,
                    },
                    {
                        path: "user-profile",
                        element: <NavigationGuard><UserProfileView/></NavigationGuard>,
                    },
                    {
                        path: "organization-settings",
                        element: <NavigationGuard><OrganizationSettingsView/></NavigationGuard>,
                    },
                    {
                        path: "unverified",
                        element: <UnverifiedView/>,
                    },
                    {
                        path: "manage-users",
                        element: <NavigationGuard><ManageUsersView/></NavigationGuard>,
                    },
                    {
                        path: "analytics",
                        element: <NavigationGuard><AnalyticsView/></NavigationGuard>,
                    },
                    {
                        path: "projects",
                        element: <NavigationGuard><ProjectListView/></NavigationGuard>,
                    },
                    {
                        path: "project/:uuid",
                        element: <NavigationGuard><ProjectView/></NavigationGuard>,
                    },
                    {
                        path: "new-project",
                        element: <NavigationGuard><NewProjectView/></NavigationGuard>,
                    },
                ],
            },
            {path: "*", element: <ErrorView/>},
        ]
    },
]);
