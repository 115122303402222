import React from "react";
import {DismissibleAlert} from "./DismissibleAlert";
import {useAlerts} from "../../hooks/useAlerts";

export const DismissibleAlertList = () => {
    const {alerts, removeAlert} = useAlerts()
    return (
        <div className="">
            {alerts.map(alert => (
                <DismissibleAlert key={alert.id}
                                  variant={alert.variant}
                                  onClose={() => removeAlert(alert.id)}>
                    {alert.message}
                </DismissibleAlert>
            ))}
        </div>
    );
}