import React from "react";
import {Col, Row} from "react-bootstrap";

interface SectionProps {
    children: any,
    sidePanel?: any,
    title?: string,
    className?: string
}

export const Section = ({children, title, className, sidePanel}: SectionProps) => {
    return (
        <div className={`mb-4 ${className}`}>
            <div>
                {title && <Row><Col><h5>{title}</h5></Col><Col>{sidePanel}</Col></Row>}
                <div className="mt-3"></div>
                {children}
            </div>
        </div>
    )
}