import React, {useState} from "react";
import {Nav, Navbar} from "react-bootstrap";
import Logo from "../logo.svg";
import {useRecoilState} from "recoil";
import {userState} from "../store";
import {Link} from "react-router-dom";
import {SettingsMenu} from "./settings/SettingsMenu";


export const TopNavBar = () => {
    const [user] = useRecoilState(userState)
    const [showSidebar, setShowSidebar] = useState(false);
    const toggleSidebar = () => setShowSidebar(!showSidebar);
    const brandIconStyle = {height: 35, width: 35};

    const userImage = (<div className="d-flex align-items-center" style={{cursor: "pointer"}} onClick={toggleSidebar}>
        <img className="ms-3 profile-picture"
             src={user?.picture}
             style={{height: 35, width: 35}}
             alt="profile"
        />
    </div>);

    return (<>
            <Navbar bg="light" data-bs-theme="light">
                <div className="d-flex justify-content-between w-100" style={{marginLeft: '12px'}}>
                    <div className="d-flex align-items-center">
                        <Navbar.Brand as={Link} to="/landing" className="d-flex align-items-center p-0 me-4">
                            <img
                                src={Logo}
                                style={brandIconStyle}
                                alt="processplot logo2"
                            />
                        </Navbar.Brand>
                    </div>

                    <div className="ms-auto me-3">
                        <Nav className="d-flex justify-content-end align-items-center " style={{marginRight: '12px'}}>
                            {!user?.email ?
                                (<Nav.Link href={`${process.env.REACT_APP_API_URL}/login`}>Sign in</Nav.Link>)
                                :
                                userImage
                            }

                        </Nav>
                    </div>
                </div>

            </Navbar>
            <SettingsMenu showSidebar={showSidebar}
                          user={user}
                          toggleSidebar={toggleSidebar}
                          userImage={userImage}></SettingsMenu>
        </>
    )
}