import {getRecoil} from "recoil-nexus";
import {organizationUuidState} from "../store";
import fetcher from "./fetcher";

export const saveTeam = async (name: string) => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('POST', `/team`, organizationUuid, {name});
    } catch (e) {
        return Promise.reject(e);
    }
}

export const deleteTeams = async (teamUuids: string[]) => {
    if (!teamUuids) {
        return Promise.resolve();
    }
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('DELETE', `/teams`, organizationUuid, teamUuids);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const saveTeams = async (teamNames: string[]) => {
    if (!teamNames) {
        return Promise.resolve();
    }
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('POST', `/teams`, organizationUuid, teamNames.map(name => ({name})));
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getTeams = async () => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('GET', `/teams`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

