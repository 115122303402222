import React from "react"

interface FullContainerProps {
    children: any
    className?: string
}

export function FullContainer(props: FullContainerProps) {
    return (<>
        <div className={`d-flex flex-column m-5 ${props.className}`}>
                {props.children}
        </div></>)
}