import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, FormControl, Row} from "react-bootstrap";
import Select from "react-select";
import {Full75CenteredContainer} from "../components/containers/Full75CenteredContainer";
import {Section} from "../components/Section";
import {Title} from "../components/Title";
import {NullableOption} from "../interfaces";
import {animatedComponents} from "../utils/common";
import {useAlerts} from "../hooks/useAlerts";
import TextareaAutosize from "react-textarea-autosize";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {NumericFormat} from "react-number-format";
import {getProcess, getProcessList} from "../api/process";
import {getOrganizationMembers} from "../api/organizations";
import {useRecoilValue} from "recoil";
import {userState} from "../store";
import {saveProject} from "../api/projects";
import {useNavigate} from "react-router";

export const NewProjectView = () => {

    const [name, setName] = useState<string>()
    const [description, setDescription] = useState<string>()
    const [processOptions, setProcessOptions] = useState<NullableOption[]>([])
    const [selectedProcess, setSelectedProcess] = useState<NullableOption>(null)
    const [teamMembers, setTeamMembers] = useState<NullableOption[]>([])
    const [startDate, setStartDate] = useState<Date | null>(new Date())
    const [dailyValue, setDailyValue] = useState<number>(0)
    const [projectedEndDate, setProjectedEndDate] = useState<Date | null>(null)
    const [projectOwner, setProjectOwner] = useState<NullableOption>()
    const {addError} = useAlerts()
    const formRef = useRef<HTMLFormElement>(null);
    const endDateRef = useRef<DatePicker | null>(null);
    const user = useRecoilValue(userState)
    const navigate = useNavigate();

    useEffect(() => {
        getProcessList()
            .then(data => data.map(process => ({value: process.uuid, label: process.name})))
            .then(options => setProcessOptions(options))
            .catch(addError)
    }, [addError]);

    useEffect(() => {
        getOrganizationMembers()
            .then(data => data.map(member => ({value: member.uuid, label: member.name})))
            .then(options => {
                setTeamMembers(options);
                setProjectOwner(options.find(uuid => uuid.label === user?.name))
            }).catch(addError)
    }, [addError]);

    useEffect(() => {
        if (selectedProcess?.value!) {
            getProcess(selectedProcess?.value!)
                .then(process => {
                    if (startDate) {
                        const newEndDate = new Date(startDate);
                        newEndDate.setDate(startDate.getDate() + (process.maxDuration || 0));
                        setProjectedEndDate(newEndDate);
                    }
                }).catch(addError)
        }
    }, [addError, selectedProcess, startDate]);

    const handleFormSubmit = () => {
        if (!formRef.current!.checkValidity()) {
            formRef.current!.reportValidity();
            return;
        }
        saveProject({
            projectName: name!,
            processUuid: selectedProcess?.value!,
            dailyValue: dailyValue,
            description: description || '',
            ownerUuid: projectOwner?.value!,
            startDate: startDate?.toISOString()!
        }).then(data => navigate(`/project/${data.uuid}`))
            .catch(addError);
    };

    return (
        <Full75CenteredContainer>
            <Title className="mb-5" title="New Project"/>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
                <Section className="mb-5">
                    <Row>
                        <Col md={4} className="d-flex flex-row align-items-center">
                            <strong>Project name</strong>
                        </Col>
                        <Col md={8}>
                            <FormControl
                                value={name}
                                type="text"
                                required={true}
                                minLength={3}
                                maxLength={100}
                                onChange={e => setName(e.currentTarget.value)}
                            />
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col md={4} className="d-flex flex-row align-items-center">
                            <strong>Project description</strong>
                        </Col>
                        <Col md={8}>
                            <FormControl
                                as={TextareaAutosize}
                                className="w-100"
                                value={description}
                                placeholder="Description"
                                onChange={e => setDescription(e.currentTarget.value)}>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={4} className="d-flex flex-row align-items-center">
                            <strong>Process</strong>
                        </Col>
                        <Col md={8}>
                            <Select
                                className="plain-input"
                                value={selectedProcess}
                                options={processOptions}
                                required={true}
                                components={animatedComponents}
                                onChange={setSelectedProcess}
                            />
                        </Col>
                    </Row>
                </Section>
                <Section title="Configuration">
                    <Row className="mt-5 mb-3">
                        <Col md={6}>
                            <Row>
                                <Col md={6} className="d-flex align-items-center"><strong>Start date</strong></Col>
                                <Col md={6}>
                                    <DatePicker
                                        className="form-control"
                                        selected={startDate}
                                        dateFormat={'dd/MM/yyyy'}
                                        onChange={date => setStartDate(date)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={6} className="d-flex align-items-center"><strong>Projected end
                                    date</strong></Col>
                                <Col md={6}>
                                    <DatePicker
                                        disabled={true}
                                        className="form-control plain-input"
                                        selected={projectedEndDate}
                                        minDate={startDate || undefined}
                                        placeholderText="n/a"
                                        required={true}
                                        ref={endDateRef}
                                        dateFormat={'dd/MM/yyyy'}
                                        onChange={date => setProjectedEndDate(date)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Row>
                                <Col md={6} className="d-flex align-items-center">
                                    <strong>Daily value</strong>
                                </Col>
                                <Col md={6}>
                                    <NumericFormat
                                        className="form-control"
                                        min={0}
                                        suffix=" €"
                                        value={dailyValue}
                                        onValueChange={(values) => setDailyValue(values.floatValue || 0)}
                                    ></NumericFormat>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={6} className="d-flex align-items-center">
                                    <strong>Project owner</strong>
                                </Col>
                                <Col md={6}>
                                    <Select
                                        className="plain-input"
                                        value={projectOwner}
                                        options={teamMembers}
                                        required={true}
                                        components={animatedComponents}
                                        onChange={setProjectOwner}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Section>
                <Row className="mt-5">
                    <Col md={12} className="d-flex flex-row">
                        <div className={"ms-auto"}><Button onClick={handleFormSubmit}>Create project</Button></div>
                    </Col>
                </Row>
            </Form>

        </Full75CenteredContainer>
    );
};