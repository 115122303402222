import {PendingInvitation} from "../../interfaces";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import React from "react";
import {INVITATION_STATUSES, ROLES} from "../../utils/Constants";

interface PendingInvitationRowProps {
    invitation: PendingInvitation,
    canRevoke: boolean,
    handleCancel: (invitation: PendingInvitation) => void,
    handleDelete: (invitation: PendingInvitation) => void,
}

export const PendingInvitationRow = ({
                                         invitation,
                                         canRevoke,
                                         handleCancel,
                                         handleDelete,
                                     }: PendingInvitationRowProps) => {
    return (<tr>
            <td className="align-middle">
                {invitation?.email}
            </td>
            <td className="align-middle" style={{paddingLeft: "10px"}}>
                {invitation.team.name}
            </td>
            <td className="align-middle" style={{paddingLeft: "10px"}}>
                {ROLES[invitation!.role]}
            </td>
            <td className="align-middle">
                {INVITATION_STATUSES[invitation.status]}
            </td>
            <td className="align-middle link-danger">
                <div className="d-flex justify-content-between align-items-center"
                     style={{cursor: "pointer"}}>
                    {canRevoke && invitation.status === 'PENDING' &&
                        <XMarkIcon className="icon-style m-auto" onClick={() => handleCancel(invitation)}/>
                    }
                    {canRevoke && invitation.status !== 'PENDING' &&
                        <XMarkIcon className="icon-style m-auto" onClick={() => handleDelete(invitation)}/>
                    }
                </div>
            </td>
        </tr>
    )
}