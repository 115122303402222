import React from "react";
import {CenterContainer} from "../components/containers/CenterContainer";

export default function LandingView() {

    return (
        <CenterContainer>
            Landing page
        </CenterContainer>
    )
}